const booleanSwitch = (state, array, key, value) => {
  const element = state[array].find(e => e.value === value);

  if (!element || element.disabled) return null;

  element[key] = !element[key];
  return element;
};

export default booleanSwitch;
