import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';

const initialState = {
  advantages: [],
  area: [],
  finish: [],
  floor: [],
  price: [],
  rooms: [],
};

const additionalOptions = {
  '1OdinPanoramnyjBalkonVKvartire': 1,
  '2DvaPanoramnyhBalkonaVKvartire': 1,
  balcony: 1,
  cornerGlazing: 1,
  dressingRoom: 1,
  frenchBalcony: 1,
  highCeiling: 1,
  kabinet: 1,
  kamin: 1,
  laundry: 1,
  livingKitchen: 1,
  loggia: 1,
  masterBedroom: 1,
  oknaNa2Storony: 1,
  oknaNa3Storony: 1,
  oknoVVannoj: 1,
  panoramicGlazing: 1,
  patio: 1,
  penthaus: 1,
  roomOnEast: 1,
  roomOnNorth: 1,
  roomOnSouth: 1,
  roomOnWest: 1,
  sauna: 1,
  terrasa: 1,
  vidNaPark: 1,
  vidNaReku: 1,
  vidVoDvor: 1,
};

export const formSlice = createSlice({
  name: 'formSlice',
  initialState,
  reducers: {
    saveDataFromForm: (_state, action) => {
      let { advantages, finish } = action.payload;
      //отфильтровываем и оставляем только те варивнты фильтров, которые есть в выборщике
      const filteredAdditional = advantages ? advantages.filter(el => additionalOptions[el]) : null;
      if (finish && finish.length > 1 && finish.includes('whiteBox')) {
        finish = [...finish, 'whiteBoxMax'];
      }
      const dataFromForm = { ...action.payload, advantages: filteredAdditional, finish };
      localStorage.setItem('form_data', JSON.stringify(dataFromForm));
    },
  },
});

export const formApi = createApi({
  reducerPath: 'formApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getFormData: builder.query({
      query: meetingId => `${DOMAIN_RELATIVE}/api/meetings/anketa/${meetingId}`,
    }),
    postFormData: builder.mutation({
      query: ({ meetingId, data }) => ({
        url: `${DOMAIN_RELATIVE}/api/meetings/anketa/${meetingId}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetFormDataQuery, usePostFormDataMutation } = formApi;

export const { saveDataFromForm } = formSlice.actions;

export default formSlice.reducer;
