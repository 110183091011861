import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Card, IconButton, Typography, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../assets/mui-styles/Colors';
import { handleOpenModelWarning } from '../../entities/ComponentsSlice';
import { changeWarningBenefit, warningBenefitSelector } from '../../entities/ProjectSlice';
import { handleBodyLock, handleBodyUnlock } from '../../utils/utils';

function ModalWarning() {
  handleBodyLock();
  const textModelWarning = useSelector(state => state.componentsState.textModelWarning);
  const warningBenefit = useSelector(warningBenefitSelector());
  const dispatch = useDispatch();
  const handleModelClose = () => {
    dispatch(handleOpenModelWarning({ open: false, text: '' }));
    if (warningBenefit) {
      dispatch(changeWarningBenefit(false));
    }
    handleBodyUnlock();
  };
  return (
    <StyledModalWarningContainer>
      <StyledModalWarningCard>
        <StyledModalWarningBtnClose onClick={handleModelClose}>
          <CloseIcon color='neutral' />
        </StyledModalWarningBtnClose>
        <WarningAmberIcon color='warning' sx={{ fontSize: '80px', mb: 2 }} />
        <Typography component='div' variant='h4' color='warning' sx={{ color: Colors.warning }}>
          Предупреждение:
        </Typography>
        <Typography component='div' variant='h6' sx={{ mt: 3, width: '70%', textAlign: 'center' }}>
          {textModelWarning}
        </Typography>
      </StyledModalWarningCard>
    </StyledModalWarningContainer>
  );
}

export default ModalWarning;

const StyledModalWarningContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999,
  display: 'flex',
  width: '100vw',
  height: '100vh',
  background: 'rgba(31, 29, 26, 0.5)',
  backdropFilter: 'blur(4px)',
});

const StyledModalWarningCard = styled(Card)({
  position: 'absolute !important',
  top: '10%',
  left: '27%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '45% !important',
  height: '80% !important',
  background: Colors.primary,
  borderRadius: '4px',
});

const StyledModalWarningBtnClose = styled(IconButton)({
  position: 'absolute !important',
  top: '20px',
  right: '20px',
  backgroundColor: Colors.backGrey,
  '&:hover': {
    backgroundColor: Colors.hoverGrey,
  },
});
