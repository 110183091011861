export const changeOriginalBenefitsForSelect = (benefits, calculatorType = 'price') => {
  return benefits.map(benefit => ({
    title: benefit?.name,
    id: String(benefit?.id),
    value: String(calculatorType === 'LotEqualizer' ? benefit?.name : benefit?.id),
  }));
};

export const formatEarlyPayments = ({ earlyPayments, earlyPaymentsType }) => {
  return earlyPayments?.map(({ sum, monthNumber }) => ({
    amount: sum || 0,
    month: monthNumber || 0,
    type: earlyPaymentsType || 'reduceTerm',
  }));
};
