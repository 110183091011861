import { Typography } from '@mui/material';

export const PaymentsScheduleTitle = () => {
  return (
    <Typography
      component='div'
      sx={{
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: '500',
        letterSpacing: '-0.5px',
      }}
    >
      График платежей
    </Typography>
  );
};
