import { Box, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Colors } from '../../../assets/mui-styles/Colors';
import { primaryTheme } from '../../../assets/mui-styles/GlobalStyles';
import { getFavoriteFlats } from '../../../entities/selectors/selectors';
import { CalculatorForLot } from '../../../widgets/calculator/CalculatorForLot';
import { CalculatorForPrice } from '../../../widgets/calculator/CalculatorForPrice';
import { CalculatorForFree } from '../../../widgets/calculator/CalculatorForFree';
import { EqualizerFlatsSliderNew } from '../../equalizer-page-new/equalzier-flats-slider/EqualizerFlatsSlider';
import style from './CalculatorTabs.module.scss';
import { calculatorFreeTab } from '../../../utils/feature-flags';

export const CalculatorTabs = ({
  propsForCalculatorByLot,
  propsForCalculatorByPrice,
  propsForCalculatorByFreeNew,
  statusSetters,
  activeTab,
  handleChangeActiveTab,
  meetingId,
  isEqualizer,
  fetchStatus,
}) => {
  const favoriteFlats = useSelector(getFavoriteFlats);

  return (
    <div className={style.content}>
      <div className={`${style.switches} ${calculatorFreeTab ? style.threeColumns : ''}`}>
        <button
          type={'button'}
          onClick={() => handleChangeActiveTab('lot')}
          className={cn(style.switch, {
            [style.switchActive]: activeTab === 'lot',
          })}
        >
          Расчет по лоту
        </button>
        <button
          type={'button'}
          onClick={() => handleChangeActiveTab('price')}
          className={cn(style.switch, {
            [style.switchActive]: activeTab === 'price',
          })}
        >
          Расчет по прайс-цене
        </button>
        {calculatorFreeTab && (
          <button
            type={'button'}
            onClick={() => handleChangeActiveTab('free')}
            className={cn(style.switch, {
              [style.switchActive]: activeTab === 'free',
            })}
          >
            Свободный расчет
          </button>
        )}
      </div>

      {isEqualizer && activeTab === 'lot' && favoriteFlats?.length > 0 && meetingId && (
        <ThemeProvider theme={primaryTheme}>
          <Box
            className={style.sliderWrapper}
            sx={{
              background: Colors.additional,
              border: '1px solid',
              borderColor: Colors.superLightGrey,
              borderTopColor: 'transparent',
              borderBottomColor: 'transparent',
            }}
          >
            <EqualizerFlatsSliderNew
              favoriteFlats={favoriteFlats}
              propsForFlatCards={activeTab === 'lot' ? propsForCalculatorByLot : propsForCalculatorByPrice}
            />
          </Box>
        </ThemeProvider>
      )}

      {activeTab === 'lot' && (
        <CalculatorForLot
          {...propsForCalculatorByLot}
          statusSetters={statusSetters}
          isEqualizer={isEqualizer}
          meetingId={meetingId}
          fetchStatus={fetchStatus}
        />
      )}

      {activeTab === 'free' && (
        <CalculatorForFree fetchStatus={fetchStatus} isEqualizer={isEqualizer} {...propsForCalculatorByFreeNew} />
      )}

      {activeTab === 'price' && (
        <CalculatorForPrice {...propsForCalculatorByPrice} statusSetters={statusSetters} fetchStatus={fetchStatus} />
      )}
    </div>
  );
};
