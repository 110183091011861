import { useGetFlatPriceNew } from './calculator-logic/useGetFlatPriceNew';
import { useSelectTermNew } from './calculator-logic/useSelectTermNew';
import { useSelectRateNew } from './calculator-logic/useSelectRateNew';
import { useSwitchFirstPaymentTypeNew } from './calculator-logic/useSwitchFirstPaymentTypeNew';
import { useSelectFirstPaymentNew } from './calculator-logic/useSelectFirstPaymentNew';
import { useGetEarlyPayment } from './calculator-logic/useGetEarlyPayment';
import { useGetFreeCalcData } from './calculator-logic/useGetFreeCalcData';
import { useEffect } from 'react';
import { useDownloadPdf } from './calculator-logic/useDownloadPdf';
import { useSendEmailForPdf } from './calculator-logic/useSendEmailForPdf';

export const useCalculatorLogicForFreeNew = (uniqueId, statusSetters) => {
  const { handleSetErrorStatus, handleSetLoadedStatus, handleSetPendingStatus } = statusSetters;
  const { flatPriceNew, minFlatPriceNew, maxFlatPriceNew, handleChangeFlatPriceNew, flatPriceNewError } =
    useGetFlatPriceNew({ uniqueId });
  const { term, handleChangeTermNew, termForDisplayNew, minTermNew, maxTermNew, termNewError } = useSelectTermNew({
    flatPriceNew,
    uniqueId,
  });

  const { rateNew, rateForDisplayNew, minRateNew, maxRateNew, handleChangeRateNew, rateError } = useSelectRateNew({
    flatPriceNew,
    uniqueId,
  });

  const { isPercentNew, handleSwitchFirstPaymentType } = useSwitchFirstPaymentTypeNew({ uniqueId });

  const {
    earlyPayments,
    earlyPaymentOptions,
    earlyPaymentsForDisplay,
    earlyPaymentsType,
    onChangeEarlyPaymentsType,
    onChangeEarlyPayment,
    createEmptyPayment,
    deletePayment,
    handlePaymentError,
    earlyPaymentsErrors,
    clearEarlyPayments,
    validateEarlyPayments,
  } = useGetEarlyPayment();

  const {
    firstPaymentPercentDisplayNew,
    firstPaymentCurrencyDisplayNew,
    firstPaymentCurrencyNew,
    firstPaymentPercentNew,

    handleChangeFirstPaymentNew,
    minFirstPaymentPercentNew,
    maxFirstPaymentPercentNew,
    minFirstPaymentCurrencyNew,
    maxFirstPaymentCurrencyNew,
    firstPaymentCurrencyNewError,
    firstPaymentPercentNewError,
  } = useSelectFirstPaymentNew({
    flatPrice: flatPriceNew,
    isPercent: isPercentNew,
    uniqueId,
  });

  const {
    totalMonths: totalCreditMonthsFree,
    yearlyPayments: freeYearlyPayments,
    creditSum: freeCreditSum,
    freeMonthlyPayment,
  } = useGetFreeCalcData({
    flatPriceNew,
    firstPaymentCurrencyNew,
    term,
    rateNew,
    earlyPayments,
    handleSetPendingStatus,
    handleSetErrorStatus,
    handleSetLoadedStatus,
    termNewError,
    rateError,
    flatPriceNewError,
    firstPaymentPercentNewError,
    firstPaymentCurrencyNewError,
    minFlatPriceNew,
    maxFlatPriceNew,
    earlyPaymentsErrors,
    validateEarlyPayments,
    earlyPaymentsType,
  });

  const { downloadPdfHandler } = useDownloadPdf({
    handleSetErrorStatus,
    handleSetLoadedStatus,
    handleSetPendingStatus,
  });

  const { onSendEmail } = useSendEmailForPdf({
    handleSetLoadedStatus,
    handleSetPendingStatus,
  });

  useEffect(() => {
    clearEarlyPayments();
  }, [flatPriceNew, term, rateNew, firstPaymentCurrencyNew, firstPaymentPercentNew]);

  return {
    flatPriceNew,
    minFlatPriceNew,
    maxFlatPriceNew,
    handleChangeFlatPriceNew,
    handleChangeTermNew,
    termForDisplayNew,
    minTermNew,
    maxTermNew,
    rateForDisplayNew,
    minRateNew,
    maxRateNew,
    rateNew,
    handleChangeRateNew,
    isPercentNew,
    handleSwitchFirstPaymentType,
    firstPaymentPercentDisplayNew,
    firstPaymentCurrencyDisplayNew,
    handleChangeFirstPaymentNew,
    minFirstPaymentPercentNew,
    maxFirstPaymentPercentNew,
    minFirstPaymentCurrencyNew,
    maxFirstPaymentCurrencyNew,
    earlyPaymentOptions,
    earlyPaymentsType,
    onChangeEarlyPaymentsType,
    earlyPaymentsForDisplay,
    onChangeEarlyPayment,
    createEmptyPayment,
    deletePayment,
    totalCreditMonthsFree,
    freeYearlyPayments,
    freeCreditSum,
    freeMonthlyPayment,
    firstPaymentCurrencyNewError,
    firstPaymentPercentNewError,
    flatPriceNewError,
    termNewError,
    rateError,
    handlePaymentError,
    earlyPaymentsErrors,
    downloadPdfHandler,
    onSendEmail,
  };
};
