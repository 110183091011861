import { useEffect, useMemo, useState } from 'react';

export const useSelectBenefit = (benefits, uniqueSuffix) => {
  const benefitName = `currBenefit${uniqueSuffix}`;
  const isLotEqualizerEstimation = uniqueSuffix === 'LotEqualizer';
  const [benefitIdentificator, setBenefitIdentificator] = useState(sessionStorage.getItem(benefitName) || '');
  const [benefitNotFound, setBenefitNotFound] = useState(false);

  const clearBenefit = () => {
    sessionStorage.removeItem(`currBenefit${uniqueSuffix}`);
    setBenefitIdentificator('');
  };

  const handleChangeBenefit = value => {
    // в расчете по лоту в value приходит название программы
    // в остальных расчетах - id
    setBenefitIdentificator(value);
    sessionStorage.setItem(benefitName, value);
  };

  const benefit = useMemo(() => {
    setBenefitNotFound(false);
    if (benefits?.length > 0 && benefitName && benefitIdentificator) {
      const benefit = benefits.find(benefit => {
        if (isLotEqualizerEstimation) {
          // в расчете по лоту в панели менеджера
          // сраниваем по имени
          // ПИК подменяет id у программ, из за этого много программ закрываются ошибками
          // поиск по имени позволяет этого избежать
          return String(benefit?.name?.toLowerCase().trim()) === benefitIdentificator?.toLowerCase()?.trim();
        } else {
          return String(benefit.id) === String(benefitIdentificator);
        }
      });

      // если есть выбранный бенефит
      // но в массиве новых бенефитов такого названия нет
      // показываем ошибку
      if (benefitIdentificator && benefitIdentificator !== 'undefined' && !benefit) {
        setBenefitNotFound(true);
        setBenefitIdentificator('');
        sessionStorage.removeItem('currTermLotEqualizer');
        setTimeout(() => {
          handleChangeBenefit();
          setBenefitNotFound(false);
        }, 200);
      }

      if (benefit) {
        setBenefitNotFound(false);
        return {
          value: isLotEqualizerEstimation ? String(benefit?.name) : benefit?.id,
          title: benefit?.name,
          id: String(benefit?.id),
          maxYears: Number(benefit?.maxYears),
          // minFirstPaymen - не опечатка; так приходит с бэка
          minFirstPayment: benefit?.minFirstPaymen,
          rate: benefit?.rate,
        };
      }
    }
    return null;
  }, [benefitIdentificator, benefits, benefitName, uniqueSuffix]);

  useEffect(() => {
    const benefitIdFromSS = sessionStorage.getItem(benefitName);
    if (benefitIdFromSS && benefitIdFromSS !== 'undefined') {
      setBenefitIdentificator(benefitIdFromSS);
    }
  }, [benefitName]);

  return {
    benefitIdentificator,
    benefit: benefit,
    handleChangeBenefit,
    benefitNotFound,
    clearBenefit,
  };
};
