import { useEffect, useState } from 'react';

export const useSwitchFirstPaymentTypeNew = ({ uniqueId }) => {
  const sessionFieldName = `isPercent${uniqueId}`;
  const [isPercentNew, setIsPercentNew] = useState(() => {
    const sessionSwitchType = sessionStorage.getItem(sessionFieldName);
    return sessionSwitchType === 'true';
  });

  const handleSwitchFirstPaymentType = () => {
    setIsPercentNew(!isPercentNew);
    sessionStorage.setItem(sessionFieldName, !isPercentNew);
  };

  useEffect(() => {
    const sessionSwitchType = sessionStorage.getItem(sessionFieldName);
    if (sessionSwitchType === 'true') {
      setIsPercentNew(true);
    } else {
      setIsPercentNew(false);
    }
  }, []);

  return { isPercentNew, handleSwitchFirstPaymentType };
};
