import { DOMAIN_RELATIVE } from '../../../utils/constants';
import { clearUserData } from '../../../utils/utils';

export const fetchFlats = async url => {
  const response = await fetch(url, {
    method: 'GET',
  });
  return response.json();
};

export const fetchMortgagesPrice = async id => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/v2/flat/mortgage/list?blockId=${id}`, {
    method: 'GET',
  });

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchBenefits = async ({ flatId, initialPayment, loanTerm, benefitId }) => {
  const response = await fetch(
    `https://api.forma.ru/v2/flat/mortgage?flatId=${flatId}&initialPayment=${initialPayment}&loanTerm=${loanTerm}&benefitId=${benefitId}`,
    {
      method: 'GET',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchInitialPayment = async ({ currBenefitLot, flatPrice }) => {
  const response = await fetch(
    `${DOMAIN_RELATIVE}/api/flat/mortgage/initialPaiment?mortgageId=${currBenefitLot}&sum=${flatPrice}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchCalcData = async ({ loanTerm, mortgageId, initialPayment, sum }) => {
  const response = await fetch(
    `${DOMAIN_RELATIVE}/api/v2/mortgage/flat?loanTerm=${loanTerm}&mortgageId=${mortgageId}&initialPayment=${initialPayment}&sum=${sum}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchLotCalcData = async ({ loanTerm, mortgageId, initialPayment, sum }) => {
  const response = await fetch(
    `${DOMAIN_RELATIVE}/api/flat/mortgage?loanTerm=${loanTerm}&mortgageId=${mortgageId}&initialPayment=${initialPayment}&sum=${sum}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

export const fetchFreeBenefits = async () => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/v2/flat/mortgage/list`, {
    method: 'GET',
  });

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

// запрос для новой версии свободного расчета
export const fetchFreeCalcData = async ({
  propertyPrice,
  initialPayment,
  loanTerm,
  interestRate,
  earlyPayments = [],
}) => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/free-calculator/mortgage`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      propertyPrice,
      initialPayment,
      loanTerm,
      interestRate,
      earlyPayments,
    }),
  });

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};

const handleBlobDownload = blob => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'график_платежей.pdf'; // Имя файла для скачивания
  document.body.appendChild(a);
  a.click(); // Инициируем клик по ссылке
  a.remove(); // Удаляем ссылку из DOM
  window.URL.revokeObjectURL(url); // Освобождаем память
};

// скачать пдф в совбодном расчете
export const downloadPdf = async ({ propertyPrice, initialPayment, loanTerm, interestRate, earlyPayments = [] }) => {
  try {
    const response = await fetch(`${DOMAIN_RELATIVE}/api/free-calculator/mortgage/pdf/download`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        propertyPrice,
        initialPayment,
        loanTerm,
        interestRate,
        earlyPayments,
      }),
    });

    if (response.status === 401) {
      clearUserData();
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Получаем данные как Blob
    const blob = await response.blob();

    // Вызываем функцию для обработки скачивания
    handleBlobDownload(blob);
  } catch (error) {
    console.error('Fetch error:', error);
  }
};

// запрос для отправки пдф на почту в свободном расчете
export const sendPdfToEmail = async ({
  propertyPrice,
  initialPayment,
  loanTerm,
  interestRate,
  earlyPayments = [],
  email = [],
}) => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/free-calculator/mortgage/pdf/email`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      propertyPrice,
      initialPayment,
      loanTerm,
      interestRate,
      earlyPayments,
      email,
    }),
  });

  if (response.status === 401) {
    clearUserData();
  }

  return response.json();
};
