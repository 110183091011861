import styles from './EmailPopup.module.scss';
import { useState } from 'react';
import { Typography, Input, IconButton, Button } from '@mui/material';
import { Box, styled } from '@mui/system';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

export const EmailPopup = ({ onClose, onSendEmail }) => {
  const [emailData, setEmailData] = useState([{ id: uuidv4(), email: '' }]);
  const [emailValidExists, setEmailValidExists] = useState(false);
  const maxEmailsAmount = 5;
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+$/;

  const onAddEmail = () => {
    if (emailData.length >= maxEmailsAmount) return;
    setEmailData([...emailData, { id: uuidv4(), email: '' }]);
  };

  const onChangeEmail = (e, index) => {
    const updatedEmailData = [...emailData];
    updatedEmailData[index].email = e.target.value;
    setEmailData(updatedEmailData);

    // Проверяем, есть ли хотя бы один валидный email
    const isAnyEmailValid = updatedEmailData.some(item => emailRegex.test(item.email));
    setEmailValidExists(isAnyEmailValid);
  };

  const handleDelete = id => {
    const updatedEmailData = emailData.filter(i => i?.id !== id);
    setEmailData(updatedEmailData);

    // Проверяем, есть ли хотя бы один валидный email после удаления
    const isAnyEmailValid = updatedEmailData.some(item => emailRegex.test(item.email));
    setEmailValidExists(isAnyEmailValid);
  };

  return (
    <>
      <StyledPopupWrapperBox />
      <StyledBox>
        <StyledFlexRowBox>
          <Typography
            sx={{
              fontSize: '37px',
              fontWeight: '500',
              lineHeight: '45px',
            }}
          >
            Введите e-mail <br /> для отправки PDF
          </Typography>
          <CloseOutlinedIcon
            onClick={onClose}
            sx={{
              width: '35px',
              height: '35px',
              cursor: 'pointer',
              position: 'absolute',
              top: '23px',
              right: '23px',
            }}
          />
        </StyledFlexRowBox>
        <StyledMainBox>
          {emailData?.map((item, index) => {
            return (
              <StyledRowContainer key={item.id}>
                <StyledInput
                  value={item.email}
                  onChange={e => onChangeEmail(e, index)}
                  disableUnderline={true}
                  placeholder='johnsmith@gmail.com'
                />
                {/* Крестик для удаления инпута */}
                {index !== 0 && (
                  <IconButton
                    className={styles.deleteBtn}
                    onClick={() => handleDelete(item?.id)}
                    sx={{
                      paddingRight: '0',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                {/* Кнопка добавления почты */}
                <StyledAddEmailContainer onClick={emailData.length < maxEmailsAmount ? onAddEmail : null}>
                  {/* Отрисовываем кнопку только напротив последнего инпута */}
                  {emailData.length < maxEmailsAmount && index === emailData.length - 1 && (
                    <>
                      <AddCircleOutlinedIcon sx={{ cursor: 'pointer', width: '24px', height: '24px' }} />
                      <button>Добавить почту</button>
                    </>
                  )}
                </StyledAddEmailContainer>
              </StyledRowContainer>
            );
          })}
        </StyledMainBox>
        <Button
          variant='text'
          sx={buttonStyles(emailValidExists)}
          onClick={() => (emailValidExists ? onSendEmail(emailData) : null)}
        >
          Отправить
        </Button>
      </StyledBox>
    </>
  );
};

const buttonStyles = isActive => ({
  height: '36px',
  backgroundColor: isActive ? 'black' : '#0000001f',
  color: isActive ? 'white' : '#00000042',
  fontSize: '14px',
  lineHeight: '24px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: isActive ? '#00000085' : '#0000001f',
  },
});

const StyledPopupWrapperBox = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  backgroundColor: '#00000042',
  zIndex: 20,
  height: '120vh',
});

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  backgroundColor: '#F9F9F9',
  zIndex: 20,
  width: '517px',
  height: 'fit-content',
  padding: '30px 18px',
});

const StyledFlexRowBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledMainBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '30px',
  gap: '10px',
});

const StyledInput = styled(Input)({
  width: '251px',
  outline: '1px solid #302E2D1F',
  borderRadius: '1px',
  backgroundColor: '#FFFFFF',

  '& .MuiInput-input': {
    height: '24px',
    padding: '8px 0 8px 12px',
  },
});

const StyledAddEmailContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '184px',
  padding: '9px 11px 9px 7px',
  marginLeft: '5px',
  fontWeight: '500',
  fontFamily: 'Unica77LLCyr-Medium',
});

const StyledRowContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
});
