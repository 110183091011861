import { useState, useRef, useEffect } from 'react';
import { styled, Box } from '@mui/system';
import { StyledTypography } from './StyledComponents';

export const YearsRow = ({ payments, activeYear, onClickYear }) => {
  const currentYear = new Date().getFullYear();
  const [gradientActive, setGradientActive] = useState(true);
  const yearsRowRef = useRef(null);
  const yearRefs = useRef([]);

  useEffect(() => {
    if (yearsRowRef.current) {
      yearsRowRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  }, [payments]);

  useEffect(() => {
    // Прокручиваем к активному году при его изменении
    if (yearRefs.current[activeYear - 1]) {
      yearRefs.current[activeYear - 1].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center', // Центрируем элемент
      });
    }
  }, [activeYear]);

  return (
    <StyledBox
      ref={yearsRowRef}
      sx={{ maskImage: gradientActive ? 'linear-gradient(to right, black 91%, transparent)' : 'none' }}
    >
      {payments?.map((payment, index) => {
        const isActiveYear = index + 1 === activeYear;

        const yearRef = el => {
          yearRefs.current[index] = el;
        };

        return (
          <StyledYearBox
            ref={yearRef}
            key={payment?.year}
            sx={{
              borderBottom: isActiveYear ? '2px solid #302E2D' : '2px solid #969694',
            }}
            onClick={() => {
              onClickYear(payment?.year);
              if (payment?.year === payments?.length) {
                setGradientActive(false);
              } else {
                setGradientActive(true);
              }
            }}
          >
            <StyledTypography color={isActiveYear ? '2px solid #302E2D' : '#969694'}>
              {currentYear + index}
            </StyledTypography>
          </StyledYearBox>
        );
      })}
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  display: 'flex',
  marginTop: '21px',
  width: '100%',
  overflowX: 'auto',
  position: 'relative',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const StyledYearBox = styled(Box)({
  minWidth: '50px',
  height: '27px',
  textAlign: 'center',
  cursor: 'pointer',
});
