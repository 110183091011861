import { useState } from 'react';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';
import { v4 as uuidv4 } from 'uuid';

export const useGetEarlyPayment = () => {
  const earlyPaymentOptions = [
    {
      id: 1,
      value: 'Платеж',
      title: 'Платеж',
      backendValue: 'reducePayment',
    },
    {
      id: 2,
      value: 'Срок',
      title: 'Срок',
      backendValue: 'reduceTerm',
    },
  ];

  const [earlyPaymentsForDisplay, setEarlyPaymentsForDisplay] = useState([]);
  const [earlyPayments, setEarlyPayments] = useState([]);
  const [earlyPaymentsErrors, setEarlyPaymentsErrors] = useState([]);
  const [earlyPaymentsType, setEarlyPaymentsType] = useState({
    id: 2,
    value: 'Срок',
    title: 'Срок',
    backendValue: 'reduceTerm',
  });

  const clearEarlyPayments = () => {
    setEarlyPaymentsForDisplay([]);
    setEarlyPayments([]);
    setEarlyPaymentsErrors([]);
  };

  const setEarlyPaymentsDebounced = useDebouncedFunction(setEarlyPayments, 600);
  // редактируем досрочный платеж
  const onChangeEarlyPayment = ({ id, key, newValue }) => {
    setEarlyPaymentsForDisplay(prev =>
      prev.map(payment => {
        if (payment.id === id) {
          return {
            ...payment,
            [key]: newValue,
            ['type']: earlyPaymentsType?.backendValue,
          };
        }
        return payment;
      }),
    );
    setEarlyPaymentsDebounced(prev =>
      prev.map(payment => {
        if (payment.id === id) {
          return {
            ...payment,
            [key]: newValue,
            ['type']: earlyPaymentsType?.backendValue,
          };
        }
        return payment;
      }),
    );
  };

  // выбираем тип всех платежей ('что уменьшить?')
  const onChangeEarlyPaymentsType = type => {
    const selectedType = earlyPaymentOptions.find(option => option.value === type);
    if (selectedType) {
      setEarlyPaymentsType(selectedType);
    }
  };

  const createEmptyPayment = () => {
    const id = uuidv4();
    setEarlyPaymentsForDisplay([...earlyPaymentsForDisplay, { id, sum: '', monthNumber: '' }]);
    setEarlyPaymentsDebounced([...earlyPaymentsForDisplay, { id, sum: '', monthNumber: '' }]);
  };

  const deletePayment = id => {
    setEarlyPaymentsForDisplay(prev => prev.filter(payment => payment.id !== id));
    setEarlyPaymentsDebounced(prev => prev.filter(payment => payment.id !== id));
    setEarlyPaymentsErrors(prev => prev.filter(error => error.id !== id));
  };

  const handlePaymentError = ({ id, sumError, monthNumberError }) => {
    setEarlyPaymentsErrors(prev => {
      const existingErrorIndex = prev.findIndex(error => error?.id === id);

      if (existingErrorIndex !== -1) {
        // Если ошибка уже существует, обновляем только переданные поля
        const existingError = prev[existingErrorIndex];
        return prev.map((error, index) => {
          if (index === existingErrorIndex) {
            return {
              id,
              sumError: sumError !== undefined ? sumError : existingError.sumError, // Обновляем только если передано
              monthNumberError: monthNumberError !== undefined ? monthNumberError : existingError.monthNumberError, // Обновляем только если передано
            };
          }
          return error;
        });
      } else {
        // Если ошибки нет, добавляем новую
        return [...prev, { id, sumError: sumError, monthNumberError: monthNumberError }];
      }
    });
  };

  return {
    earlyPayments,
    earlyPaymentOptions,
    earlyPaymentsForDisplay,
    onChangeEarlyPayment,
    createEmptyPayment,
    deletePayment,
    earlyPaymentsType,
    onChangeEarlyPaymentsType,
    handlePaymentError,
    earlyPaymentsErrors,
    clearEarlyPayments,
  };
};
