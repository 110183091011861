import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ru';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.locale('ru');
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_WITH_STRING_MONTH = 'DD MMMM, YYYY';
export const DATE_FORMAT_WITH_DOTS = 'DD.MM.YYYY';
export const SHORT_DATE_FORMAT = 'DD.MM';
export const DATE_TIME_TIMEZONE = 'YYYY-MM-DD HH:mm:ss ZZ';
export const MSC_TIMEZONE = 'Europe/Moscow';

export const daysOfWeekPluralForm = {
  0: 'воскресеньям',
  1: 'понедельникам',
  2: 'вторникам',
  3: 'средам',
  4: 'четвергам',
  5: 'пятницам',
  6: 'субботам',
};

export const firstDayOfCurrentMonth = dayjs().date(1);

export const getLastDayUntilTheAddMonth = forMonth => {
  const targetMonth = dayjs().add(forMonth, 'M');

  return targetMonth.date(targetMonth.daysInMonth());
};

export const getFormatDate = (dt, format) => (dt && dayjs(dt).isValid() ? dayjs(dt).format(format) : '');

/**
 * Возвращает в формате ISO 8601 с обрезанной таймзоной. На сервер отправляем в таком формате
 * @example
 *  2023-03-16T13:02:41
 */
export const toServerFormatString = dt => getFormatDate(dt, SERVER_FORMAT);
/**
 * Получает dayjs из формата как приходит с сервера
 * @example
 *  2023-03-16T13:02:41
 */
export function fromServerFormat(date) {
  return dayjs(date);
}

export const nowMSC = () => dayjs().tz(MSC_TIMEZONE);
export const getMscLine = () => {
  return dayjs(toServerFormatString(nowMSC()));
};

export const toTimeString = dt => getFormatDate(dt, TIME_FORMAT);
export const toDateString = dt => getFormatDate(dt, DATE_FORMAT);
export const toDateMonthString = dt => getFormatDate(dt, DATE_WITH_STRING_MONTH);
export const toDateWithDots = dt => getFormatDate(dt, DATE_FORMAT_WITH_DOTS);
export const toRussianStyleDateTime = dt => getFormatDate(dt, 'DD MMMM [в] HH:mm');
export const toRussianStyleDateTimeWithDots = dt => getFormatDate(dt, 'DD.MM.YYYY [в] HH:mm');
export const toShortDateString = dt => getFormatDate(dt, SHORT_DATE_FORMAT);
export const toMscTimezoneString = dt => dayjs(dt).tz(MSC_TIMEZONE).format(DATE_TIME_TIMEZONE);

export const toWeekOfDay = dt => dayjs(dt).format('dddd');

export const IsNowBetweenDates = (startDate, endDate) => {
  const currentMsc = toServerFormatString(nowMSC());

  return currentMsc >= startDate && currentMsc <= endDate;
};
export const isBefore = startDate => {
  const currentMsc = toServerFormatString(nowMSC());

  return currentMsc < startDate;
};
