import { Box, Stack, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../entities/api/routes';
import ButtonUnlogin from '../../features/button-unlogin/ButtonUnlogin';
import SearchContacts from '../../features/search-contacts/SearchContacts';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const isWaba = location.pathname.includes(ROUTES.waba.root);
  const showSearch = isWaba ? location.pathname?.split('/')?.filter(path => path)?.length > 1 : false;

  const onClickLogo = () => {
    navigate(ROUTES.root);
  };

  return (
    <StyledGeneralPageTitleStack direction='row' alignItems='center' justifyContent='space-between'>
      <StyledGeneralPageLogo onClick={onClickLogo}>
        <svg
          id='Layer_1'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1255.95 255.14'
          style={{ overflow: 'visible' }}
        >
          <g>
            <path
              d='M321,260.87q-57.35,0-92.45-36.55-35.13-36.9-35.12-96.75t35.12-96.75Q263.68-5.73,321-5.73t92.45,36.55q34.76,36.2,34.76,96.75t-34.76,96.75Q378.33,260.88,321,260.87Zm-77-133.3q0,41.93,21.15,66.29T321,218.23q35.12,0,56.26-24.37,20.78-24,20.78-66.29T377.27,61.28Q356.12,36.91,321,36.91q-34.77,0-55.9,24.37T244,127.57Z'
              fill='#333130'
            />
            <path
              d='M684.28,255.14H629.1L566,159.46H520.52v95.68H472.15V0H585.74q41.93,0,66.11,21.32T676,78.48q0,28.3-15.59,48.19t-43.89,27.06Zm-101-215.36H520.52V120.4h62.71q20.43,0,32.25-11.11t11.83-29.38q0-18.27-11.83-29.21T583.23,39.78Z'
              fill='#333130'
            />
            <path
              d='M869.72,121.48,912.36,0H982.6V255.14H936.37V177.38q0-71.67,1.43-126.5-7.87,26.88-19.35,58.77l-51.6,145.49H827.08l-52-145.49q-13.62-39.78-19-58,1.43,54.48,1.43,125.78v77.76H711V0h70.23l44.08,124q4.29,12.55,21.85,69.16Q858.62,153.38,869.72,121.48Z'
              fill='#333130'
            />
            <path
              d='M1256,255.14h-51.24l-20.07-56.26h-111.8l-19.71,56.26h-49.81L1100.43,0h56.62ZM1097.21,130.08l-9.68,27.59H1170l-10-27.59q-12.56-31.89-31.54-87.44Q1113.33,87.09,1097.21,130.08Z'
              fill='#333130'
            />
            <path d='M180.24,0V43.36H48.38v72.29h120V159h-120v96.15H0V0Z' fill='#333130' />
          </g>
        </svg>
      </StyledGeneralPageLogo>

      <StyledBlockRight>
        {showSearch && (
          <StyledSearch>
            <SearchContacts />
          </StyledSearch>
        )}
        <ButtonUnlogin text='Выйти' />
      </StyledBlockRight>
    </StyledGeneralPageTitleStack>
  );
}

export default Header;

const StyledGeneralPageTitleStack = styled(Stack)({
  alignItems: 'center',
  padding: '24px 5vw',
});

const StyledGeneralPageLogo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  svg: {
    width: '100px',
  },
});

const StyledBlockRight = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const StyledSearch = styled(Box)({
  marginRight: '10px',
});
