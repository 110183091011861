import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { clearUserData } from '../utils/utils';
import { fetchProjectsByRole } from './api/api';

const initialState = {
  benefits: [],
  benefitsChanged: [],
  currProjectId: null,
  projects: [],
};

export const BenefitsPageSlice = createSlice({
  name: 'benefitsData',
  initialState,
  reducers: {
    changeCurrProjectId: (state, action) => {
      state.currProjectId = action.payload;
      state.benefitsChanged = state.benefits.filter(benefit => benefit.blockId === action.payload);
    },
    changeBenefitIsSite: (state, action) => {
      const { id, isRedPrice, isSite, benefits, bestPrice } = action.payload;
      let arr;
      if (isRedPrice || bestPrice) {
        arr = benefits.map(benefit =>
          benefit.id === id ? { ...benefit, isSite, isRedPrice: false, bestPrice: false } : benefit,
        );
      } else {
        arr = benefits.map(benefit => (benefit.id === id ? { ...benefit, isSite } : benefit));
      }
      state.benefitsChanged = arr;
    },
    changeBenefitIsRedPrice: (state, action) => {
      const { id, isRedPrice, isSite, benefits } = action.payload;
      let arr;
      if (isSite) {
        arr = benefits.map(benefit => (benefit.id === id ? { ...benefit, isRedPrice, isSite } : benefit));
      } else {
        arr = benefits.map(benefit => (benefit.id === id ? { ...benefit, isRedPrice } : benefit));
      }
      state.benefitsChanged = arr;
    },
    changeBenefitBestPrice: (state, action) => {
      const { id, isSite, benefits, bestPrice } = action.payload;
      let arr;
      if (isSite) {
        arr = benefits.map(benefit => (benefit.id === id ? { ...benefit, bestPrice, isSite } : benefit));
      } else {
        arr = benefits.map(benefit => (benefit.id === id ? { ...benefit, bestPrice } : benefit));
      }
      state.benefitsChanged = arr;
    },
    changeBenefitText: (state, action) => {
      const { id, title, desc, benefits } = action.payload;
      state.benefitsChanged = benefits.map(benefit =>
        benefit.id === id ? { ...benefit, newName: title ? title : '', newDescription: desc ? desc : '' } : benefit,
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProjectsByRole.fulfilled, (state, action) => {
      state.projects = action.payload.projectsByRole
        .sort((a, b) => a.project_id - b.project_id)
        .map(({ name, project_id }) => {
          return {
            id: project_id,
            customName: name,
          };
        });

      if (state.projects[0]?.id) {
        BenefitsPageSlice.caseReducers.changeCurrProjectId(state, { payload: state.projects[0]?.id });
      }
    });
    builder.addMatcher(benefitsDataApi.endpoints.getBenefitsProjectsList.matchFulfilled, (state, { payload }) => {
      state.projects = [...state.projects, ...payload];
      if (state.projects[0]?.id) {
        BenefitsPageSlice.caseReducers.changeCurrProjectId(state, { payload: state.projects[0]?.id });
      }
    });
    builder.addMatcher(benefitsDataApi.endpoints.getBenefitsList.matchFulfilled, (state, { payload }) => {
      state.benefits = payload;
      state.benefitsChanged = payload.filter(item => item.blockId === state.currProjectId);
    });
    builder.addMatcher(benefitsDataApi.endpoints.updateBenefitsData.matchFulfilled, (state, { payload }) => {
      state.benefits = payload;
      state.benefitsChanged = payload.filter(item => item.blockId === state.currProjectId);
    });
  },
});

export const benefitsDataApi = createApi({
  reducerPath: 'benefitsDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    // TODO УДАЛИТЬ ПОСЛЕ ПЕРЕМЕЩЕНИЯ В ProjectSLice
    getBenefitsProjectsList: builder.query({
      query: () => `${DOMAIN_RELATIVE}/api/v2/block/`,
    }),
    getBenefitsList: builder.query({
      query: () => `${DOMAIN_RELATIVE}/api/v2/api-benefits`,
      providesTags: [{ type: 'Benefits', id: 'BENEFIT' }],
    }),
    updateBenefitsData: builder.mutation({
      query: ({ benefits, currProjectId }) => ({
        url: `${DOMAIN_RELATIVE}/api/v2/api-benefits?blockId=${currProjectId}`,
        method: 'PATCH',
        body: {
          data: benefits,
        },
      }),
      invalidatesTags: [{ type: 'Benefits', id: 'BENEFIT' }],
      async onQueryStarted(_args, { _, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          if (error.status === 401) {
            clearUserData();
          }
        }
      },
    }),
  }),
});

export const {
  changeCurrProjectId,
  changeBenefitIsSite,
  changeBenefitIsRedPrice,
  changeBenefitText,
  changeBenefitBestPrice,
} = BenefitsPageSlice.actions;

export const allProjectsList = state => {
  return state.benefitsData.projects;
};

export const currProjectIdSelector = state => {
  return state.benefitsData.currProjectId;
};

export const benefitsSelector = state => {
  return state.benefitsData.benefits || [];
};

export const benefitsChangedSelector = state => {
  return state.benefitsData.benefitsChanged || [];
};

export default BenefitsPageSlice.reducer;

export const { useGetBenefitsListQuery, useUpdateBenefitsDataMutation, useGetBenefitsProjectsListQuery } =
  benefitsDataApi;
