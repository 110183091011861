import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';

const initialState = {
  device: null,
  vendor: null,
  useragent: null,
  orientation: null,
  width: null,
  height: null,
  user: JSON.parse(localStorage.getItem('user')) || {},
  userRoles: [],
  error: {
    isError: false,
    status: '',
    message: '',
  },
};

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${DOMAIN_RELATIVE}/api/v2/auth/login`,
    prepareHeaders: headers => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
    credentials: 'include',
  }),
  endpoints: builder => ({
    authUser: builder.mutation({
      query: data => ({
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const AuthSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    defineDevice: (state, action) => {
      const { width, userAgent, vendor, height } = action.payload;
      state.width = width;
      state.height = height;
      state.vendor = vendor;
      const mql = window.matchMedia('(orientation: portrait)');
      if (mql.matches) {
        state.orientation = 'portrait';
      } else {
        state.orientation = 'landscape';
      }
      if (userAgent.includes('iPhone')) {
        state.useragent = 'iPhone';
      } else if (userAgent.includes('iPad')) {
        state.useragent = 'iPad';
      } else if (userAgent.includes('Android')) {
        state.useragent = 'Android';
      } else state.useragent = 'none';

      if (width <= 740) {
        state.device = 'isMobile';
      } else if (width > 740 && width <= 1180) {
        state.device = 'isMobile';
      } else {
        state.device = 'isDesktop';
      }
    },

    defineUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.authUser.matchFulfilled, (state, { payload }) => {
        localStorage.setItem('user', JSON.stringify(payload.user));
        localStorage.setItem('user_roles', JSON.stringify(payload.user.roles));

        state.user = payload.user;
        state.userRoles = payload.user.roles;
        state.error = { isError: false, status: '', message: '' };
      })
      .addMatcher(authApi.endpoints.authUser.matchRejected, (state, { payload }) => {
        state.error.status = payload.status;
        state.error.message = payload.data.message;
        state.error.isError = true;
      });
  },
});

export const { defineDevice, defineUserRoles } = AuthSlice.actions;

export const deviceSelector = () => state => {
  return state.userInfo;
};

export const userRolesSelector = state => {
  return state.userInfo.userRoles;
};

export const errorStatus = () => state => {
  return state.userInfo.error;
};

export const selectUser = state => {
  return state.userInfo.user;
};

export const { useAuthUserMutation } = authApi;
export default AuthSlice.reducer;
