import { EstimationBlock } from '../../estimation-block/EstimationBlock';
import { Box } from '@mui/system';
import cn from 'classnames';
import style from '../CalculatorBlock.module.scss';
import { EmailPopup } from '../../email-popup/EmailPopup';
import { PaymentsSchedule } from '../../paymentsSchedule/PaymentsSchedule';
import { CalculatorFreeButton } from '../../calculator-free-button/calculatorFreeButton';
import { pdfButtonInCalculatorForFree } from '../../../../utils/feature-flags';
import { checkIfCalculatorByFreeNewPropsValid } from '../../../../utils/calculatorUtils';

export const FreeEstimationBlock = ({
  propsForCalculatorByFreeNew,
  fetchStatus,
  activeTab,
  isEmailPopupForFreeOpen,
  setIsEmailPopupForFreeOpen,
}) => {
  const shouldDisplay = checkIfCalculatorByFreeNewPropsValid({ propsForCalculatorByFreeNew });

  return (
    shouldDisplay && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: 'fit-content',
          gap: '20px',
        }}
        className={cn({ [style.wrapperStealth]: activeTab !== 'free' })}
      >
        <EstimationBlock {...propsForCalculatorByFreeNew} fetchStatus={fetchStatus} isFreeTab={true} />
        <>
          {isEmailPopupForFreeOpen && (
            <EmailPopup
              onClose={() => setIsEmailPopupForFreeOpen(false)}
              onSendEmail={emailData => {
                propsForCalculatorByFreeNew?.onSendEmail?.({
                  emailData: emailData,
                  propertyPrice: propsForCalculatorByFreeNew?.flatPriceNew,
                  initialPayment: propsForCalculatorByFreeNew?.firstPaymentCurrencyDisplayNew,
                  loanTerm: propsForCalculatorByFreeNew?.termForDisplayNew,
                  interestRate: propsForCalculatorByFreeNew?.rateNew,
                  earlyPayments: propsForCalculatorByFreeNew?.earlyPaymentsForDisplay,
                  earlyPaymentsType: propsForCalculatorByFreeNew?.earlyPaymentsType?.backendValue,
                });
                setIsEmailPopupForFreeOpen(false);
              }}
            />
          )}
          <PaymentsSchedule payments={propsForCalculatorByFreeNew?.freeYearlyPayments} />
          {pdfButtonInCalculatorForFree && (
            <Box display='flex' gap='16px'>
              <CalculatorFreeButton onClick={() => setIsEmailPopupForFreeOpen(true)} type='email'>
                Отправить по e-mail
              </CalculatorFreeButton>
              <CalculatorFreeButton
                type='pdf'
                onClick={() =>
                  propsForCalculatorByFreeNew?.downloadPdfHandler({
                    propertyPrice: propsForCalculatorByFreeNew?.flatPriceNew,
                    initialPayment: propsForCalculatorByFreeNew?.firstPaymentCurrencyDisplayNew,
                    loanTerm: propsForCalculatorByFreeNew?.termForDisplayNew,
                    interestRate: propsForCalculatorByFreeNew?.rateNew,
                    earlyPayments: propsForCalculatorByFreeNew?.earlyPaymentsForDisplay,
                    earlyPaymentsType: propsForCalculatorByFreeNew?.earlyPaymentsType?.backendValue,
                  })
                }
              >
                Скачать
              </CalculatorFreeButton>
            </Box>
          )}
        </>
      </Box>
    )
  );
};
