import { Box, styled, Button, SvgIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { benefitsUpdateTimeSelector, setIsBenefitsUpdated } from '../../entities/ProjectSlice';
import { Colors } from '../../assets/mui-styles/Colors';

const UpdateNotification = () => {
  const dispatch = useDispatch();
  const benefitsUpdateTime = useSelector(benefitsUpdateTimeSelector);
  const notificationText = `На сервер были загружены новые ипотечные программы от ${benefitsUpdateTime}.`;

  const handleFunction = () => {
    dispatch(setIsBenefitsUpdated(false));
  };

  return (
    <Wrapper>
      <Icon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.08398 12.75H10.9173V14.5833H9.08398V12.75ZM9.08398 5.41667H10.9173V10.9167H9.08398V5.41667ZM9.99148 0.833334C4.93148 0.833334 0.833984 4.94 0.833984 10C0.833984 15.06 4.93148 19.1667 9.99148 19.1667C15.0607 19.1667 19.1673 15.06 19.1673 10C19.1673 4.94 15.0607 0.833334 9.99148 0.833334ZM10.0007 17.3333C5.94898 17.3333 2.66732 14.0517 2.66732 10C2.66732 5.94833 5.94898 2.66667 10.0007 2.66667C14.0523 2.66667 17.334 5.94833 17.334 10C17.334 14.0517 14.0523 17.3333 10.0007 17.3333Z'
          fill='white'
        />
      </Icon>

      <TextWrapper>
        <Title>Обновление ставок</Title>
        <Message>{notificationText}</Message>
      </TextWrapper>

      <CloseButton onClick={handleFunction}>ЗАКРЫТЬ</CloseButton>
    </Wrapper>
  );
};

export default UpdateNotification;

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: '24px',
  padding: '16px',
  color: Colors.primary,
  background: 'rgba(51, 49, 48, 0.7)',
  borderRadius: '4px',
});

const Icon = styled(SvgIcon)({
  width: '20px',
  height: '20px',
});

const TextWrapper = styled(Box)({
  flexGrow: 1,
  margin: '0 16px',
  boxSizing: 'border-box',
});

const Title = styled(Box)({
  fontSize: '16px',
  letterSpacing: '0.15px',
});

const Message = styled(Box)({
  marginTop: '6px',
  fontSize: '12px',
  lineHeight: '150%',
  letterSpacing: '0.4px',
});

const CloseButton = styled(Button)({
  color: Colors.primary,
  fontSize: '15px',
  lineHeight: '26px',
  letterSpacing: '0.46px',
  textTransform: 'uppercase',
  padding: '0px',
  minWidth: '70px',
});
