import { useState, useEffect } from 'react';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';

export const useSelectTermNew = ({ flatPriceNew, uniqueId }) => {
  const [termForDisplayNew, setTermForDisplayNew] = useState(1);
  const [term, setTerm] = useState(1);
  const minTermNew = 1;
  const maxTermNew = 30;
  const sessionFieldName = `term${uniqueId}`;

  // ошибка ввода срока
  const [termNewError, setTermNewError] = useState('');

  const setTermDebounced = useDebouncedFunction(setTerm, 600);

  const handleChangeTermNew = value => {
    setTermForDisplayNew(value);
    setTermDebounced(value);
    sessionStorage.setItem(sessionFieldName, value);
  };

  useEffect(() => {
    if (!termForDisplayNew && termForDisplayNew !== 0) {
      return setTermNewError('');
    } else if (termForDisplayNew < minTermNew) {
      return setTermNewError('Увеличьте срок кредита');
    } else if (termForDisplayNew > maxTermNew) {
      return setTermNewError('Уменьшите срок кредита');
    } else {
      return setTermNewError('');
    }
  }, [minTermNew, maxTermNew, termForDisplayNew]);

  // если в сроке ошибка и меняется стоиомсть недвижимости
  // устанавливаем дефолтное значение срока
  useEffect(() => {
    if (termNewError !== '') {
      setTerm(1);
      setTermForDisplayNew(1);
    }
  }, [flatPriceNew]);

  useEffect(() => {
    const termNew = sessionStorage.getItem(sessionFieldName);
    if (termNew) {
      setTerm(Number(termNew));
      setTermForDisplayNew(Number(termNew));
    }
  }, []);

  return {
    term,
    handleChangeTermNew,
    termForDisplayNew,
    minTermNew,
    maxTermNew,
    termNewError,
  };
};
