import { Button, IconButton, Modal, Stack, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryColors } from '../../assets/mui-styles/Colors';
import {
  bookFlatSelector,
  bookModalDateSelector,
  bookModalStatusSelector,
  changeBookModalStatus,
  useBookFlatMutation,
} from '../../entities/BookingSlice';
import { handleOpenModelWarning } from '../../entities/ComponentsSlice';
import { flatsSelector } from '../../entities/EqualizerSlice';
import BookCalendar from '../../features/book-calendar/BookCalendar';
import BookFlatSelect from '../../features/book-flat-select/BookFlatSelect';
import { handleBodyLock, handleBodyUnlock } from '../../utils/utils';

function BookModal({ cardFlat, cardModalStatus, cardSetModalStatus, stopPropagation, isCommercialManager }) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const modalStatus = useSelector(bookModalStatusSelector());
  const bookDate = useSelector(bookModalDateSelector());
  const flat = useSelector(bookFlatSelector());
  const flats = useSelector(flatsSelector());
  const foundFlat = cardFlat ? cardFlat : flats.find(i => i.number === flat);
  const bookDateObject = bookDate ? new Date(bookDate) : null;
  const bookDateISO = bookDateObject?.toISOString();
  const activeMeeting = useSelector(state => state.meetingsData.activeMeeting);
  const activeMeetingId = activeMeeting?.id;

  const [book, setBook] = useState(false);
  const [bookFlat] = useBookFlatMutation();

  const handleClose = () => {
    if (cardModalStatus) {
      cardSetModalStatus(false);
    } else dispatch(changeBookModalStatus(false));
  };

  const handleBook = e => {
    e.stopPropagation();
    bookFlat({
      meetingId: activeMeetingId,
      data: {
        flat_id: foundFlat.id,
        flat_data: { ...foundFlat },
        date: bookDateISO,
      },
    })
      .unwrap()
      .then(() => handleClose())
      .catch(error => {
        dispatch(
          handleOpenModelWarning({
            open: true,
            text: error.data?.message,
          }),
        );
      });
  };

  const handleNext = e => {
    e.stopPropagation();
    setBook(true);
  };

  const handleClick = e => {
    if (stopPropagation) {
      e.stopPropagation();
    } else return;
  };

  useEffect(() => {
    handleBodyLock();
    return () => {
      handleBodyUnlock();
    };
  }, []);

  const titleModal = isCommercialManager ? 'Помещение забронировано?' : 'Квартира забронирована?';

  return (
    <Modal open={cardModalStatus ? cardModalStatus : modalStatus} onClose={handleClose} onClick={e => handleClick(e)}>
      <StyledInner ref={ref} sx={{ background: PrimaryColors.backgroundDefault }}>
        {!book && (
          <Stack>
            <Typography sx={{ fontSize: '37px', lineHeight: '45px', mb: '40px' }}>{titleModal}</Typography>
            <Stack sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
              <Button onClick={handleClose} sx={{ width: '100%' }} variant='text'>
                Нет
              </Button>
              <Button onClick={e => handleNext(e)} sx={{ width: '100%' }} variant='contained'>
                Да
              </Button>
            </Stack>
          </Stack>
        )}

        {book && (
          <Stack>
            <Typography sx={{ fontSize: '37px', lineHeight: '45px', mb: '30px' }}>{titleModal}</Typography>
            <Stack sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', mb: '40px' }}>
              <BookFlatSelect cardFlat={cardFlat} isCommercialManager={isCommercialManager} />
              <BookCalendar />
            </Stack>
            <Button sx={{ width: '100%' }} variant='contained' onClick={e => handleBook(e)}>
              Сохранить
            </Button>
          </Stack>
        )}

        <IconButton
          size='small'
          instance='close'
          sx={{
            width: '48px',
            height: '48px',
            position: 'absolute',
            top: '19px',
            right: '19px',
          }}
          onClick={handleClose}
        >
          <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M20.7082 2.348L18.6519 0.291748L10.4998 8.44383L2.34775 0.291748L0.291504 2.348L8.44359 10.5001L0.291504 18.6522L2.34775 20.7084L10.4998 12.5563L18.6519 20.7084L20.7082 18.6522L12.5561 10.5001L20.7082 2.348Z'
              fill='#302E2D'
            />
          </svg>
        </IconButton>
      </StyledInner>
    </Modal>
  );
}

export default BookModal;

const StyledInner = styled(Stack)({
  position: 'absolute',
  top: '50%',
  left: 'calc(50%)',
  minWidth: '608px',
  padding: '30px',
  transform: 'translate(-50%, -50%)',
});
