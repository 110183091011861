import dayjs from 'dayjs';
import { makeName } from '../utils/utils';
import { SERVER_FORMAT } from '../utils/dates';
import { eventsTypeFullTimeline } from '../pages/graphic-job-page/utils/constants';

const setTitle = eventData => {
  if (eventData) {
    if (eventData.name) {
      return `${eventData.name} (${eventData.room})`;
    }

    if (eventData.title) {
      return eventData.title;
    }
  }

  return 'Без названия';
};

const meetingsScheduleAdapter = {
  adaptManagerList: managersData =>
    managersData.map(manager => ({
      id: manager.id,
      name: makeName(manager.lastName, manager.firstName, manager.patronymic),
      workingHours: manager.workingHours,
    })),

  adaptMeetingsSlots: (events, date) => {
    const fullTimelineDate = {
      startTime: dayjs(date).set('hour', 8).format(SERVER_FORMAT),
      endTime: dayjs(date).set('hour', 22).format(SERVER_FORMAT),
    };

    return events.map(e => {
      const endDate = dayjs(e.startTime).add(e.duration, 'm');

      return {
        graphicId: e.eventId,
        uniqId: `bar_${e.type}_${e.eventId}`,
        startDjs: eventsTypeFullTimeline.includes(e.type) ? fullTimelineDate.startTime : e.startTime,
        endDjs: eventsTypeFullTimeline.includes(e.type) ? fullTimelineDate.endTime : endDate.format(SERVER_FORMAT),
        type: e.type,
        title: setTitle(e.eventData),
        managerIds: e.managerIds,
        eventData: e.eventData ?? {},
        online: e.eventData?.isOnline || false,
        clientName: e.eventData?.name || '',
        meetingStatus: e.status ?? {},
        clientCome: e.eventData?.clientStart || false,
        managerCome: e.eventData?.managerStart || false,
      };
    });
  },
};

export default meetingsScheduleAdapter;
