import { EstimationBlock } from '../../estimation-block/EstimationBlock';
import { Box } from '@mui/system';
import cn from 'classnames';
import style from '../CalculatorBlock.module.scss';

export const PriceEstimationBlock = ({ propsForCalculatorByPrice, fetchStatus, meetingId, activeTab }) => {
  const checkIfDisplayPriceEstimationBlock = () => {
    const {
      minFirstPayment,
      maxFirstPayment,
      benefit,
      term,
      maxTerm,
      minTerm,
      firstPaymentCommitted,
      flatPrice,
      calcData,
      initialPayment,
    } = propsForCalculatorByPrice;

    const isMonthlyPaymentExists = calcData?.monthlyPayment;
    const isPriceWithMarginExists = initialPayment?.priceWithMargin;
    const isMinMaxFirstPaymentValid = minFirstPayment < maxFirstPayment;
    const ifFlatPriceIsPositive = Number(flatPrice) !== 0;
    const isBenefitExists = Boolean(benefit);
    const isTermValid = term >= minTerm && term <= maxTerm;
    const isFirstPaymentCommittedValid =
      firstPaymentCommitted <= maxFirstPayment && firstPaymentCommitted >= minFirstPayment;
    const inCalculationValid =
      isMinMaxFirstPaymentValid &&
      ifFlatPriceIsPositive &&
      isBenefitExists &&
      isMonthlyPaymentExists &&
      isPriceWithMarginExists;

    return inCalculationValid && isTermValid && isFirstPaymentCommittedValid;
  };

  const shouldDisplay = checkIfDisplayPriceEstimationBlock();

  return (
    shouldDisplay && (
      <Box sx={{ height: '100%' }} className={cn({ [style.wrapperStealth]: activeTab !== 'price' })}>
        <EstimationBlock {...propsForCalculatorByPrice} fetchStatus={fetchStatus} meetingId={meetingId} />
      </Box>
    )
  );
};
