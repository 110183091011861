import { useSelector } from 'react-redux';
import { userRolesSelector } from '../../entities/AuthSlice';
import style from './RoleCard.module.scss';
import { bookingRedirectToPartner } from '../../utils/feature-flags';

function RoleCard({ item, onlyMobile }) {
  const userRoles = useSelector(userRolesSelector);
  const isBooking = item?.title === 'Бронирование';

  if (Array.isArray(item.role)) {
    const hasAccess = userRoles.some(role => item.role.includes(role));
    if (!hasAccess) {
      return <></>;
    }
  }

  if (!Array.isArray(item.role) && !userRoles.includes('admin') && !userRoles.includes(item.role)) {
    return <></>;
  }
  return (
    <div className={`${style.links__item} ${onlyMobile ? style.mobile : ''}`}>
      <h2 className={style.links__heading}>{item.title}</h2>
      <p className={style.links__paragraph}>{item.text}</p>
      <a
        href={item.link}
        target={isBooking && bookingRedirectToPartner ? '_blank' : '_self'}
        className={style.links__link}
      >
        Перейти
      </a>
    </div>
  );
}

export default RoleCard;
