export const Colors = {
  primary: '#FFFFFF',
  secondary: '#333130',
  additional: '#F5F5F5',
  success: '#66BB6A',
  error: '#FF455B',
  warning: '#f57c00',
  grey: '#989898',
  black: '#212121',
  darkGrey: '#5F5F5F',
  superLightGrey: '#E0E0E0',
  lightGrey: '#B4B4B4',
  backGrey: 'rgba(245, 245, 245, 0.5)',
  hoverGrey: '#EEEEEE',
  tableGrey: '#E6E6E6',
};

export const PrimaryColors = {
  primary: '#302E2D',
  secondary: '#302E2D61',
  white: '#FFFFFF',
  success: '#64C768',
  border: 'rgba(48, 46, 45, 0.12)',
  borderLessOpacity: 'rgba(48, 46, 45, 0.08)',
  hover: '#D9DAD3',
  secondaryBG: '#E4E5DF',
  greyBG: '#E5E5E5',
  greyText: '#969694',
  background: '#DEDEDE',
  backgroundDefault: '#F9F9F9',
  filledInputBackground: 'rgba(48, 46, 45, 0.06)',
  shades: 'rgb(244, 244, 244)',
  tooltip: 'rgba(97, 97, 97, 0.9)',
  disabled: 'rgba(48, 46, 45, 0.38)',
  black: '#1A1A1A',
  redDark: '#C62828',
  secondaryTwo: 'rgba(48, 46, 45, 0.60)',
  textSecondary: '#302E2D99',
};

export const Mixins = {
  defaultBorder: `1px solid ${PrimaryColors.border}`,
  primaryColorBorder: `1px solid ${PrimaryColors.primary}`,
  secondaryColorBorder: `1px solid ${PrimaryColors.secondaryBG}`,
  disabledBorder: `1px dashed ${PrimaryColors.border}`,
  errorBorder: `1px solid ${Colors.error}`,
};
