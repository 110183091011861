import { useState, useEffect } from 'react';

export const useGetFlatPriceNew = ({ uniqueId }) => {
  const [flatPriceNew, setFlatPriceNew] = useState(null);
  const minFlatPriceNew = 1_000_000;
  const maxFlatPriceNew = 200_000_000;
  const storageFieldName = `flatPrice${uniqueId}`;
  // ошибка ввода цены
  const [flatPriceNewError, setFlatPriceNewError] = useState('');

  const handleChangeFlatPriceNew = value => {
    setFlatPriceNew(value);
    sessionStorage.setItem(storageFieldName, value);
  };

  useEffect(() => {
    if (flatPriceNew === 0 || !flatPriceNew) {
      return setFlatPriceNewError('');
    } else if (flatPriceNew < minFlatPriceNew) {
      return setFlatPriceNewError('Увеличьте стоимость квартиры');
    } else if (flatPriceNew > maxFlatPriceNew) {
      return setFlatPriceNewError('Уменьшите стоимость квартиры');
    } else {
      return setFlatPriceNewError('');
    }
  }, [flatPriceNew, minFlatPriceNew, maxFlatPriceNew]);

  useEffect(() => {
    setFlatPriceNew(Number(sessionStorage.getItem(storageFieldName)));
  }, []);

  return {
    flatPriceNew,
    minFlatPriceNew,
    maxFlatPriceNew,
    handleChangeFlatPriceNew,
    flatPriceNewError,
  };
};
