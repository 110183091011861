import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import Typography from '@mui/material/Typography';
import { BlockIds, MEET_STATES } from '../constants';

export const createWebsiteLink = flat => {
  if (flat.block_id === BlockIds.FORST_BLOCK_ID) {
    return `https://forst.forma.ru/flats/#/visual/${flat.section.number}/${flat.floor}/${flat.number}/?lst=1`;
  }
  if (flat.block_id === BlockIds.REPUBLIC_BLOCK_ID) {
    return `https://republic.forma.ru/flat/${flat.id}`;
  }
  if (flat.block_id === BlockIds.PORTLAND_BLOCK_ID) {
    return `https://portland.forma.ru/flat/${flat.id}`;
  }
  if (flat.block_id === BlockIds.SOUL_BLOCK_ID) {
    return `https://soul.forma.ru/flat/${flat.id}`;
  }
  if (flat.block_id === BlockIds.MOMENTS_BLOCK_ID) {
    return `https://moments.forma.ru/flat/${flat.id}`;
  }
  return 'нет id';
};

export const calculateBulkId = (flat, bulks) => {
  if (bulks && flat) {
    const typeId = bulks.find(i => i.id === flat.bulk_id)?.type_id;
    if (typeId === 1) {
      return 'Квартира';
    }
    if (typeId === 4) {
      return 'Коммерческое помещение';
    }
    if (typeId === 5) {
      return 'Паркинг';
    }
    if (typeId === 6) {
      return 'Келлер';
    }
    return 'ошибка';
  }
  return '';
};

export const calculateFlatStatus = flat => {
  if (flat.has_advertising_price === 1) {
    return 'резерв под рекламу';
  }
  if (flat.status === 'free') {
    return 'свободно';
  }
  if (flat.status === 'reserve') {
    return 'резерв';
  }
  return 'ошибка';
};

export const switchMeetingStatus = status => {
  switch (status) {
    case MEET_STATES.CLIENT_IN_OFFICE: {
      return (
        <>
          <PersonPinOutlinedIcon />
          <Typography variant='body1R' color='primary'>
            Клиент в офисе
          </Typography>
        </>
      );
    }
    case MEET_STATES.APPROVED: {
      return (
        <>
          <DoneOutlinedIcon />
          <Typography variant='body1R' color='primary'>
            Подтверждена
          </Typography>
        </>
      );
    }
    case MEET_STATES.NOT_APPROVED: {
      return (
        <>
          <WatchLaterOutlinedIcon color='secondary' />
          <Typography color='secondary' variant='body1R'>
            Не подтверждена
          </Typography>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export const phoneNumberToString = number => {
  if (!number && number !== 0) {
    return '';
  }
  const str = number?.toString();
  const firstDigit = str.charAt(0);
  let formattedNumber;

  if (firstDigit === '8' || firstDigit === '7') {
    formattedNumber = `+7 (${str.slice(1, 4)}) ${str.slice(4, 7)}-${str.slice(7, 9)}-${str.slice(9)}`;
  } else {
    formattedNumber = `+7 (${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6, 8)}-${str.slice(8)}`;
  }

  return formattedNumber;
};

export const tomorrowOrTodayDate = dateTimeString => {
  const date = new Date(dateTimeString);
  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

  if (date.toDateString() === today.toDateString()) {
    return `Сегодня в ${date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`;
  }
  if (date.toDateString() === tomorrow.toDateString()) {
    return `Завтра в ${date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`;
  }
  return `${date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  })} в ${date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`;
};

// Трансформируем дату в формате YYYY-MM-DD в формат MM.YYYY
export const convertDateIntoMonthAndYear = date => {
  if (!date) return null;
  // Get the month and year from the date
  let month = (1 + date.getMonth()).toString();
  const year = date.getFullYear();

  // Pad the month with a leading zero if necessary
  month = month.length > 1 ? month : `0${month}`;

  // Return the formatted date
  return `${month}.${year}`;
};

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export function convertTimeToMonthWordAndNoYear(inputTime) {
  // Define month names in Russian
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  // Parse the input time
  const dateTime = new Date(inputTime);

  // Extract date and time details
  const date = dateTime.getDate();
  const month = months[dateTime.getMonth()];
  let hours = dateTime.getHours();
  let minutes = dateTime.getMinutes();

  // Pad hours and minutes with zero if they are less than 10
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  // Format the time
  return `${date} ${month} в ${hours}:${minutes}`;
}

export const findFlat = (flats, currHouse, currBulk, currSection, flatNumber) => {
  return flats.find(item => {
    if (
      (!currHouse || item.bulk.houseName.toString() === currHouse) &&
      (!currBulk || item.bulk.name.toString() === currBulk) &&
      (!currSection || item.section.number.toString() === currSection)
    ) {
      return item.number.toString() === flatNumber.toString();
    }
    return false;
  });
};
