import { useState, useEffect } from 'react';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';

export const useSelectRateNew = ({ flatPriceNew, uniqueId }) => {
  const [rateNew, setRateNew] = useState(1);
  const [rateForDisplayNew, setRateForDisplayNew] = useState(1);
  const minRateNew = 1;
  const maxRateNew = 99;
  const sessionFieldName = `rate${uniqueId}`;

  // ошибка ввода данных
  const [rateError, setRateError] = useState('');

  const setRateNewDebounced = useDebouncedFunction(setRateNew, 600);

  const handleChangeRateNew = value => {
    setRateForDisplayNew(value);
    setRateNewDebounced(value);
    sessionStorage.setItem(sessionFieldName, value);
  };

  // следим за ошибками

  useEffect(() => {
    if (!rateForDisplayNew && rateForDisplayNew !== 0) {
      return setRateError('');
    } else if (rateForDisplayNew < minRateNew) {
      return setRateError('Увеличьте процентную ставку');
    } else if (rateForDisplayNew > maxRateNew) {
      return setRateError('Уменьшите процентную ставку');
    } else {
      return setRateError('');
    }
  }, [minRateNew, maxRateNew, rateForDisplayNew]);

  // если в сроке ошибка и меняется стоиомсть недвижимости
  // устанавливаем дефолтное значение срока
  useEffect(() => {
    if (rateError !== '') {
      setRateNew(1);
      setRateForDisplayNew(1);
    }
  }, [flatPriceNew]);

  useEffect(() => {
    const sessionRate = sessionStorage.getItem(sessionFieldName);
    if (sessionRate) {
      setRateNew(Number(sessionRate));
      setRateForDisplayNew(Number(sessionRate));
    }
  }, []);

  return {
    rateNew,
    rateForDisplayNew,
    minRateNew,
    maxRateNew,
    handleChangeRateNew,
    rateError,
  };
};
