import { useEffect, useState, useCallback, useMemo } from 'react';

export const useSelectTerm = (uniqueSuffix, maxYears, project) => {
  const termName = `currTerm${uniqueSuffix}`;

  const termValue = useMemo(() => {
    const termValue = sessionStorage.getItem(termName);

    if (termValue !== 'undefined' && termValue !== null && Number(termValue) > 0 && Number(termValue) <= maxYears) {
      return termValue;
    }
    return maxYears || 1;
  }, [termName, maxYears]);

  const [term, setTerm] = useState(termValue);
  const [termCommitted, setTermPaymentCommitted] = useState(Number(sessionStorage.getItem(termName)));
  const handleChangeTerm = useCallback(
    value => {
      setTerm(Number(value));
      sessionStorage.setItem(termName, value);
    },
    [termName],
  );
  const handleChangeTermCommitted = useCallback(
    value => {
      handleChangeTerm(value);
      setTermPaymentCommitted(value);
    },
    [handleChangeTerm],
  );

  useEffect(() => {
    setTerm(Number(termValue));
    setTermPaymentCommitted(Number(termValue));
  }, [termName, maxYears, project, termValue]);

  return { term, handleChangeTerm, termCommitted, handleChangeTermCommitted };
};
