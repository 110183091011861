import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Card, Typography, styled } from '@mui/material';
import ButtonFull from '../../features/button-full/ButtonFull';
import { clearUserData, handleBodyLock, handleReload } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { handleIsError } from '../../entities/ComponentsSlice.js';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../assets/mui-styles/Colors.js';

const ModalError = () => {
  handleBodyLock();

  const dispatch = useDispatch();
  const errorCode = useSelector(state => state.componentsState.error.code);
  const errorMessage = useSelector(state => state.componentsState.error.message);

  return (
    <StyledModalErrorContainer>
      <StyledModalErrorCard>
        <ErrorOutlineIcon color='error' sx={{ fontSize: '80px', mb: 2 }} />
        <Typography variant='h4' sx={{ fontWeight: 600 }} color='error'>
          ОШИБКА
        </Typography>
        <Typography variant='h2' sx={{ fontWeight: 600, mt: '20px' }} color='secondary'>
          {errorCode}
        </Typography>
        <Typography variant='h6' sx={{ mt: 3, mb: 8, width: '70%', textAlign: 'center' }}>
          {errorMessage}
        </Typography>
        <ButtonFull
          handleFunction={() => {
            dispatch(handleIsError({ isError: false, message: '', code: '' }));
            handleReload();
          }}
          text='Перезагрузить страницу'
          color='error'
        />
        <ButtonFull
          handleFunction={() => {
            dispatch(handleIsError({ isError: false, message: '', code: '' }));
            clearUserData();
          }}
          text='Покинуть аккаунт'
          color='secondary'
        />

        <StyledCloseIcon onClick={() => dispatch(handleIsError({ isError: false, message: '', code: '' }))} />
      </StyledModalErrorCard>
    </StyledModalErrorContainer>
  );
};

export default ModalError;

const StyledModalErrorContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999,
  display: 'flex',
  width: '100vw',
  height: '100vh',
  background: 'rgba(31, 29, 26, 0.5)',
  backdropFilter: 'blur(4px)',
});

const StyledModalErrorCard = styled(Card)({
  position: 'absolute !important',
  top: '10%',
  left: '27%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '45% !important',
  height: '80% !important',
  background: Colors.primary,
  borderRadius: '4px',
});

const StyledCloseIcon = styled(CloseIcon)({
  position: 'absolute',
  top: '20px',
  right: '20px',
  width: '35px',
  height: '35px',
  cursor: 'pointer',

  '&:hover': {
    fill: 'red',
  },
});
