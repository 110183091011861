import { useEffect, useState } from 'react';

export const useSwitchPercentOrSum = (handleChangeFirstPaymentValue, firstPaymentValue, priceWithMargin) => {
  const [isPercent, setIsPercent] = useState(false);
  const [percentFirstPayment, setPercentFirstPayment] = useState(0);
  const handleChangePercentValue = value => {
    setPercentFirstPayment(value);
    handleChangeFirstPaymentValue(Math.round(((value / 100) * priceWithMargin) / 10) * 10);
  };

  const handleSwitchValue = () => {
    setIsPercent(prev => !prev);
  };

  useEffect(() => {
    setPercentFirstPayment(Math.round(((firstPaymentValue * 100) / priceWithMargin) * 10) / 10);
  }, [firstPaymentValue, priceWithMargin]);

  return { isPercent, percentFirstPayment, handleSwitchValue, handleChangePercentValue };
};
