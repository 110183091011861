import Loader from '../../../../shared/loader/Loader';
import ErrorBlock from '../../../../widgets/error-block/ErrorBlock';

export const FetchStatusHandler = ({ fetchStatus }) => {
  return (
    <>
      {fetchStatus === 'pending' && <Loader />}
      {fetchStatus === 'error' && <ErrorBlock />}
    </>
  );
};
