import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';

export const CustomAutocompleteInput = ({ options, value, onChange, label, error }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        options={options}
        value={value || null}
        onChange={(_, value) => onChange(value)}
        getOptionLabel={option => option?.value || option}
        disableClearable
        sx={{ width: '100%' }}
        renderInput={params => (
          <TextField
            {...params}
            type='text'
            color='secondary'
            label={label}
            variant='outlined'
            sx={{ backgroundColor: Colors.primary }}
          />
        )}
      />
      {error && (
        <Typography color='error' variant='body2' sx={{ mt: 1, ml: 1.8 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};
