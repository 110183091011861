import { useState, useCallback, useEffect } from 'react';
import { fetchFreeCalcData } from './dataFetchers';
import { checkIfCalculatorByFreeNewPropsValid } from '../../../utils/calculatorUtils';

export const useGetFreeCalcData = ({
  flatPriceNew,
  firstPaymentCurrencyNew,
  term,
  rateNew,
  earlyPayments,
  handleSetPendingStatus,
  handleSetLoadedStatus,
  termNewError,
  rateError,
  flatPriceNewError,
  firstPaymentPercentNewError,
  firstPaymentCurrencyNewError,
  minFlatPriceNew,
  maxFlatPriceNew,
  earlyPaymentsType,
}) => {
  const [freeCalcData, setFreeCalcData] = useState(null);
  const [creditSum, setCreditSum] = useState(0);
  const [freeMonthlyPayment, setFreeMonthlyPayment] = useState(0);

  const fetchData = useCallback(async () => {
    if (
      !checkIfCalculatorByFreeNewPropsValid({
        propsForCalculatorByFreeNew: {
          freeCreditSum: creditSum,
          flatPriceNew,
          termNewError,
          rateError,
          flatPriceNewError,
          firstPaymentPercentNewError,
          firstPaymentCurrencyNewError,
          minFlatPriceNew,
          maxFlatPriceNew,
          rateNew,
        },
      })
    ) {
      return;
    }

    const filteredEarlyPayments = earlyPayments.map(({ sum, monthNumber }) => ({
      amount: sum || 0, // переименовываем sum в amount
      month: monthNumber || 0, // переименовываем month в monthNumber
      type: earlyPaymentsType?.backendValue || 'reduceTerm',
    }));
    handleSetPendingStatus();
    const res = await fetchFreeCalcData({
      propertyPrice: flatPriceNew,
      initialPayment: firstPaymentCurrencyNew,
      loanTerm: term,
      interestRate: rateNew,
      earlyPayments: filteredEarlyPayments, //filteredEarlyPayments,
    });

    setFreeCalcData(res);
    setFreeMonthlyPayment(res?.yearlyPayments?.[0]?.monthlyPayments?.[0]?.monthlyPayment || 0);
    handleSetLoadedStatus();
  }, [
    flatPriceNew,
    firstPaymentCurrencyNew,
    term,
    rateNew,
    earlyPayments,
    minFlatPriceNew,
    maxFlatPriceNew,
    creditSum,
    termNewError,
    rateError,
    flatPriceNewError,
    firstPaymentPercentNewError,
    firstPaymentCurrencyNewError,
    earlyPaymentsType,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setCreditSum(flatPriceNew - firstPaymentCurrencyNew);
  }, [flatPriceNew, firstPaymentCurrencyNew]);

  return {
    ...(freeCalcData || {}),
    creditSum,
    freeMonthlyPayment,
  };
};
