import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import style from './InputLogin.module.scss';

const InputLogin = ({ value, setValue }) => {
  const [validate, setValidate] = useState(true);
  const [helperText, setHelperText] = useState('');

  const localPartPattern = /^[^\s@]+/; // Локальная часть (до @)
  const atSymbolPattern = /@/; // Символ @
  const domainPartPattern = /[^\s@]+/; // Доменная часть (после @)
  const topLevelDomainPattern = /\.[^\s@]+$/; // Часть после точки в домене

  const emailPattern = new RegExp(
    `${localPartPattern.source}${atSymbolPattern.source}${domainPartPattern.source}${topLevelDomainPattern.source}`,
  );

  const validateEmail = email => {
    return emailPattern.test(email.toString().toLowerCase());
  };

  const handleChangeValue = event => {
    setValue(event.target.value);
    setValidate(validateEmail(event.target.value));
  };

  useEffect(() => {
    if (!validate) {
      setHelperText('Введите корректный email');
    } else {
      setHelperText('');
    }
  }, [validate]);

  return (
    <TextField
      error={!validate}
      type='email'
      color='secondary'
      value={value}
      autoComplete='on'
      helpertext={helperText}
      onChange={handleChangeValue}
      variant='outlined'
      className={style.inputLoginInput}
      label='Логин'
    />
  );
};

export default InputLogin;
