import { Card, Typography, Box, InputAdornment, styled } from '@mui/material';
import { Colors } from '../../assets/mui-styles/Colors';
import { CustomSelect } from './ui/CustomSelect';
import { CustomInput } from './ui/CustomInput';
import { CustomInputWithSlider } from './ui/CustomInputWithSlider';
import { CustomSwitch } from './ui/CustomSwitch';
import { NumberSumFormatDefault } from './ui/InputComponentsForInputWithSlider';
import { EarlyPaymentCard } from './ui/EarlyPaymentCard';
import { toFormat, toFormatDefault } from '../../utils/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { checkIfCalculatorByFreeNewPropsValid } from '../../utils/calculatorUtils';

export const CalculatorForFree = ({
  fetchStatus,
  flatPriceNew,
  minFlatPriceNew,
  maxFlatPriceNew,
  handleChangeFlatPriceNew,
  handleChangeTermNew,
  termForDisplayNew,
  minTermNew,
  maxTermNew,
  rateForDisplayNew,
  minRateNew,
  maxRateNew,
  handleChangeRateNew,
  isPercentNew,
  handleSwitchFirstPaymentType,
  firstPaymentPercentDisplayNew,
  firstPaymentCurrencyDisplayNew,
  handleChangeFirstPaymentNew,
  minFirstPaymentPercentNew,
  maxFirstPaymentPercentNew,
  minFirstPaymentCurrencyNew,
  maxFirstPaymentCurrencyNew,
  earlyPaymentOptions,
  earlyPaymentsForDisplay,
  onChangeEarlyPayment,
  createEmptyPayment,
  deletePayment,
  totalCreditMonthsFree,
  freeCreditSum,
  firstPaymentCurrencyNewError,
  firstPaymentPercentNewError,
  flatPriceNewError,
  termNewError,
  rateError,
  earlyPaymentsType,
  onChangeEarlyPaymentsType,
  handlePaymentError,
  rateNew,
}) => {
  return (
    <>
      <StyledCard>
        <Typography variant='h3' fontSize='28px' fontWeight='500'>
          Рассчитать платеж
        </Typography>
        <StyledContainer>
          <Box width='40%'>
            <CustomInput
              disabled={fetchStatus === 'pending'}
              placeholder='20 000 000'
              helperText={flatPriceNewError}
              error={flatPriceNewError !== ''}
              valueInput={flatPriceNew ? toFormat(flatPriceNew) : ''}
              endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
              label='Стоимость объекта недвижимости'
              inputComponent={NumberSumFormatDefault}
              onChangeInput={value => handleChangeFlatPriceNew(value)}
              fetchStatus={fetchStatus}
            />
          </Box>
          {flatPriceNew >= minFlatPriceNew && flatPriceNew <= maxFlatPriceNew && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                  <CustomInputWithSlider
                    fetchStatus={fetchStatus}
                    helperText={isPercentNew ? firstPaymentPercentNewError : firstPaymentCurrencyNewError}
                    error={isPercentNew ? firstPaymentPercentNewError !== '' : firstPaymentCurrencyNewError !== ''}
                    onChangeInput={value => {
                      if (Number.isNaN(value)) return;
                      handleChangeFirstPaymentNew(value);
                    }}
                    onChangeSlider={handleChangeFirstPaymentNew}
                    onChange={value => handleChangeFirstPaymentNew(value)}
                    valueSlider={isPercentNew ? firstPaymentPercentDisplayNew : firstPaymentCurrencyDisplayNew}
                    value={isPercentNew ? firstPaymentPercentDisplayNew : firstPaymentCurrencyDisplayNew}
                    valueInput={
                      isPercentNew ? firstPaymentPercentDisplayNew : toFormatDefault(firstPaymentCurrencyDisplayNew)
                    }
                    label='Сумма первоначального взноса'
                    min={isPercentNew ? minFirstPaymentPercentNew : minFirstPaymentCurrencyNew}
                    max={isPercentNew ? maxFirstPaymentPercentNew : maxFirstPaymentCurrencyNew}
                    minValueText={isPercentNew ? `${minFirstPaymentPercentNew}%` : toFormat(minFirstPaymentCurrencyNew)}
                    maxValueText={isPercentNew ? `${maxFirstPaymentPercentNew}%` : toFormat(maxFirstPaymentCurrencyNew)}
                    endAdornment={
                      <InputAdornment position='end'>
                        {isPercentNew ? toFormat(firstPaymentCurrencyDisplayNew) : `${firstPaymentPercentDisplayNew} %`}
                      </InputAdornment>
                    }
                    onChangeCommitted={handleChangeFirstPaymentNew}
                  />
                  <CustomSwitch
                    onClick={handleSwitchFirstPaymentType}
                    checked={isPercentNew}
                    label='Сумма первого взноса, %'
                    sx={{
                      position: 'relative',
                      right: '10px',
                    }}
                  />
                </Box>
                <Box width='24%'>
                  <CustomInputWithSlider
                    disabled={fetchStatus === 'pending'}
                    helperText={termNewError}
                    error={termNewError !== ''}
                    onChange={e => handleChangeTermNew(e)}
                    onChangeInput={e => {
                      // не даем ввести больше 2 символов
                      if (Array.from(String(e))?.length <= 2) {
                        handleChangeTermNew(e);
                      }
                    }}
                    value={termForDisplayNew}
                    label='Срок кредита'
                    min={minTermNew}
                    max={maxTermNew}
                    minValueText={minTermNew}
                    maxValueText={maxTermNew}
                    inputType='number'
                    endAdornment={<InputAdornment position='end'>лет</InputAdornment>}
                    fetchStatus={fetchStatus}
                  />
                </Box>
                <Box width='32%'>
                  <CustomInputWithSlider
                    disabled={fetchStatus === 'pending'}
                    helperText={rateError}
                    error={rateError !== ''}
                    onChange={handleChangeRateNew}
                    onChangeInput={e => {
                      // не даем ввести больше 3 символов
                      if (Array.from(String(e))?.length <= 3) {
                        handleChangeRateNew(e);
                      }
                    }}
                    value={rateForDisplayNew}
                    label='Процентная ставка'
                    min={minRateNew}
                    max={maxRateNew}
                    minValueText={minRateNew}
                    maxValueText={maxRateNew}
                    inputType='number'
                    endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                    fetchStatus={fetchStatus}
                  />
                </Box>
              </Box>
            </>
          )}
        </StyledContainer>
      </StyledCard>
      {checkIfCalculatorByFreeNewPropsValid({
        propsForCalculatorByFreeNew: {
          freeCreditSum,
          flatPriceNew,
          minFlatPriceNew,
          maxFlatPriceNew,
          termNewError,
          rateError,
          flatPriceNewError,
          firstPaymentPercentNewError,
          firstPaymentCurrencyNewError,
          rateNew,
        },
      }) && (
        <StyledCard>
          <Typography variant='h3' fontSize='24px' fontWeight='500'>
            Рассчитать досрочное погашение
          </Typography>
          <Box width='30%'>
            <CustomSelect
              label='Что уменьшить?'
              handleChangeCurrentItem={onChangeEarlyPaymentsType}
              currentItem={{ value: earlyPaymentsType?.value, title: earlyPaymentsType?.title } || ''}
              items={earlyPaymentOptions}
            />
          </Box>
          {earlyPaymentsForDisplay?.map((payment, index) => {
            return (
              <EarlyPaymentCard
                key={payment?.id}
                payment={payment}
                index={index}
                deletePayment={deletePayment}
                onChangeEarlyPayment={onChangeEarlyPayment}
                handlePaymentError={handlePaymentError}
                fetchStatus={fetchStatus}
                totalCreditMonthsFree={totalCreditMonthsFree}
              />
            );
          })}
          <StyledAddPaymentButton onClick={createEmptyPayment}>
            <StyledAddCircleIcon />
            <Typography fontSize={14}>Добавить платеж</Typography>
          </StyledAddPaymentButton>
        </StyledCard>
      )}
    </>
  );
};

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '22px',
  background: Colors.additional,
  border: '1px solid',
  borderColor: Colors.superLightGrey,
  borderTopColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '30px 24px',
  ':last-child': {
    marginTop: '20px',
  },
});

const StyledAddPaymentButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 16px 8px 12px',
  background: Colors.additional,
  width: 'fit-content',
  cursor: 'pointer',
});

const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

const StyledAddCircleIcon = styled(AddCircleIcon)({
  width: '20px',
  height: '20px',
});
