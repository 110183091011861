import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { bookModalStatusSelector, changeBookModalStatus } from '../../../entities/BookingSlice';
import { handleOpenModelWarning } from '../../../entities/ComponentsSlice';
import { changeFetchStatus } from '../../../entities/EqualizerSlice';
import BookModal from '../../../widgets/book-modal/BookModal';

export const EqualizerBookButton = ({ flatData, benefit, firstPayment, term }) => {
  const dispatch = useDispatch();
  const modalStatus = useSelector(bookModalStatusSelector());

  const flatId = flatData.id;
  const flatGuid = flatData.guid;
  const benefitGuid = benefit.guid;

  const handleClick = () => {
    sessionStorage.setItem('currFlatEq', flatData.number);

    const bookFlat = () => {
      dispatch(changeFetchStatus('pending'));
      fetch('https://api.forma.ru/v1/booking/init', {
        method: 'POST',
        body: JSON.stringify({
          flatId,
          benefitGuid,
          loanTerm: Number(term),
          firstPayment: Number(firstPayment),
        }),
      })
        .then(res => res.json())
        .then(data => {
          if (data.key) {
            dispatch(changeFetchStatus('load'));
            const url = `https://booking.forma.ru/booking/${flatGuid}?key=${data.key}`;
            setTimeout(() => {
              window.open(url, '_blank').focus();
              dispatch(changeBookModalStatus(true));
            }, 10);
          } else {
            throw new Error('Нет необходимого ключа для бронирования');
          }
        })
        .catch(error => {
          const errorMessage = error.data?.message ? error.data?.message : error.message;
          dispatch(changeFetchStatus('load'));
          dispatch(
            handleOpenModelWarning({
              open: true,
              text: errorMessage,
            }),
          );
        });
    };
    bookFlat();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant='contained'
        sx={{
          display: 'inline-block',
          width: '100%',
          height: '100%',
          textTransform: 'inherit',
        }}
      >
        Забронировать
      </Button>

      {modalStatus && <BookModal cardFlat={flatData} />}
    </>
  );
};
