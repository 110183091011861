import React from 'react';
import { NumericFormat } from 'react-number-format';

export const PercentFormatCustom = React.forwardRef((props, ref) => {
  return <NumericFormat {...props} getInputRef={ref} thousandSeparator=' ' valueIsNumericString suffix=' %' />;
});

export const NumberSumFormatCustom = React.forwardRef((props, ref) => {
  return <NumericFormat {...props} getInputRef={ref} thousandSeparator=' ' valueIsNumericString suffix=' ₽' />;
});

export const NumberSumFormatDefault = React.forwardRef((props, ref) => {
  return <NumericFormat {...props} getInputRef={ref} thousandSeparator=' ' valueIsNumericString />;
});

export const NumberFormatCustom = React.forwardRef((props, ref) => {
  return <NumericFormat {...props} getInputRef={ref} thousandSeparator=' ' valueIsNumericString />;
});
