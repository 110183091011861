import { BlockIds, DOMAIN_RELATIVE } from '../../../utils/constants';
import { isPaveVisibleInCalculator } from '../../../utils/feature-flags';

export const initialProjects = [
  {
    id: 0,
    value: '0',
    title: 'Republic',
    url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=427`,
    active: false,
    blockId: BlockIds.REPUBLIC_BLOCK_ID,
  },
  {
    id: 1,
    value: '1',
    title: 'Først',
    url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=380`,
    active: true,
    blockId: BlockIds.FORST_BLOCK_ID,
  },
  {
    id: 2,
    value: '2',
    title: 'Portland',
    url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1420`,
    active: false,
    blockId: BlockIds.PORTLAND_BLOCK_ID,
  },
  {
    id: 3,
    value: '3',
    title: 'Soul',
    url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1246`,
    active: false,
    blockId: BlockIds.SOUL_BLOCK_ID,
  },
  {
    id: 4,
    value: '4',
    title: 'Moments',
    url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1457`,
    active: false,
    blockId: BlockIds.MOMENTS_BLOCK_ID,
  },
  ...(isPaveVisibleInCalculator
    ? [
        {
          id: 5,
          value: '5',
          title: 'Pave',
          url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=2168`,
          active: false,
          blockId: BlockIds.PAVE_BLOCK_ID,
        },
      ]
    : []),
];
