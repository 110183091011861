import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  calcCompareDataSelector,
  compareDataCheckSelector,
  fetchCalcCompareData,
  fetchCompareCalcCheck,
  fetchDeleteCompareData,
} from '../../../entities/EqualizerSlice';
import { useGetFavoritesFlatsQuery } from '../../../entities/FavoritesSlice';
import style from './EqualizerCompareButton.module.scss';
import { EqualizerBookButton } from '../equalizer-book-button/EqualizerBookButton';

export const EqualizerCompareButton = ({
  currentBenefit,
  meetingId,
  loanTerm,
  flats,
  firstPaymentCommitted,
  flatData,
}) => {
  const dispatch = useDispatch();
  const compareCheck = useSelector(compareDataCheckSelector);
  const calcCompareData = useSelector(calcCompareDataSelector);
  const benefitId = currentBenefit.id;
  const { refetch } = useGetFavoritesFlatsQuery(meetingId);

  const handleAddCompare = () => {
    const data = {
      flatId: Number(flatData?.id),
      benefitId: Number(benefitId),
      initialPayment: firstPaymentCommitted,
      loanTerm: Number(loanTerm),
      flat_data: flatData,
    };
    dispatch(fetchCalcCompareData({ data, meetingId })).finally(() => refetch());
  };

  const handleRemoveCompare = () => {
    dispatch(fetchDeleteCompareData({ benefitId, meetingId }));
  };

  useEffect(() => {
    if (flatData?.id) {
      dispatch(
        fetchCompareCalcCheck({
          meetingId,
          loanTerm,
          initialPayment: firstPaymentCommitted,
          benefitId,
          flatId: flatData?.id,
        }),
      );
    }
  }, [benefitId, dispatch, flatData?.id, loanTerm, meetingId, calcCompareData, firstPaymentCommitted]);

  return (
    <>
      {!compareCheck?.result && (
        <Button
          onClick={handleAddCompare}
          variant='text'
          sx={{
            textTransform: 'none',
            width: '100%',
          }}
          className={style.button}
        >
          Добавить программу в сравнение
          <PlaylistAddIcon />
        </Button>
      )}

      {compareCheck?.result && (
        <Button
          onClick={handleRemoveCompare}
          variant='text'
          className={style.button}
          sx={{
            textTransform: 'none',
            width: '100%',
          }}
        >
          Программа добавлена в сравнение
          <PlaylistAddCheckIcon />
        </Button>
      )}
      {flatData?.bookingStatus === 'active' && (
        <EqualizerBookButton
          benefit={currentBenefit}
          firstPayment={firstPaymentCommitted}
          flatData={flatData}
          term={loanTerm}
        />
      )}
    </>
  );
};
