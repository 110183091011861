import { styled } from '@mui/system';

export const StyledTypography = styled('div')(({ width, color }) => ({
  fontFamily: 'Unica77LLCyr-Medium',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  color: color || '#302E2D',
  width: width || 'auto',
}));
