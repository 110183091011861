import { Box, Typography, styled } from '@mui/material';
import { Colors } from '../../assets/mui-styles/Colors';

function MortgageColumnBox({ title, value }) {
  return (
    <EstimationItem sx={{ width: '100%' }}>
      <EstimationItemHeading component='div' variant='h6'>
        {title}
      </EstimationItemHeading>
      <EstimationItemSum>{new Intl.NumberFormat('ru-RU').format(Number.parseInt(value))} ₽</EstimationItemSum>
    </EstimationItem>
  );
}
export default MortgageColumnBox;

const EstimationItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'center',
  width: 'auto',
  padding: '20px',
  backgroundColor: Colors.primary,
  border: Colors.superLightGrey,
  borderRadius: '0px',
});

// Стили для заголовка элемента
const EstimationItemHeading = styled(Typography)({
  fontSize: '12px',
  lineHeight: '150%',
  margin: 0,
});

// Стили для суммы
const EstimationItemSum = styled(Typography)({
  marginBottom: '10px',
  fontSize: '28px',
  lineHeight: '32.68px',
});
