import { Box, Button, Card, Stack, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { errorStatus, useAuthUserMutation } from '../../entities/AuthSlice';
import InputLogin from '../../features/input-login/InputLogin';
import InputPassword from '../../features/input-password/InputPassword';
import { handleBodyLock } from '../../utils/utils';
import { useLocation } from 'react-router-dom';
import { Colors } from '../../assets/mui-styles/Colors';

const ModalAuth = () => {
  handleBodyLock();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const formRef = useRef(null);

  const [authUser] = useAuthUserMutation();
  const error = useSelector(errorStatus());

  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  const redirectLink = location.search?.replace('?redirect_url=', '');
  const isRedirect = user && user?.redirectUrl === redirectLink;

  const handleAuthUser = e => {
    e?.preventDefault();
    const email = login;
    authUser(JSON.stringify({ email, password })).then(() => {
      if (isRedirect) window.location.replace(redirectLink);
    });
  };

  useEffect(() => {
    //при клике на  'Enter' и отсутствии фокуса у обоих полей формы
    //вызываем onSubmit и при этом блокируем дефолтное поведение формы вызовом кнопки submitBtnRef.current
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        formRef.current.requestSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <StyledModalAuthContainer>
      <StyledModalAuthCard>
        <StyledModalAuthStack
          as='form'
          ref={formRef}
          onSubmit={e => handleAuthUser(e)}
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          onKeyDown={e => {
            e.stopPropagation();
          }}
        >
          <Typography component='div' variant='h4' sx={{ fontWeight: 600 }} color='secondary'>
            Авторизация
          </Typography>
          {error.message === 'not valid email or password' && (
            <Typography component='div' variant='div' sx={{ fontWeight: 400 }} color='error'>
              Неверный логин или пароль
            </Typography>
          )}
          {error.isError && error.message !== 'not valid email or password' && (
            <Typography component='div' variant='div' sx={{ fontWeight: 400 }} color='error'>
              Что-то пошло не так. Попробуйте еще раз.
            </Typography>
          )}
          <InputLogin value={login} setValue={setLogin} />
          <InputPassword value={password} setValue={setPassword} />
          <StyledButtonFullBtn type='submit' variant='contained' color='secondary'>
            Войти
          </StyledButtonFullBtn>
        </StyledModalAuthStack>
      </StyledModalAuthCard>
    </StyledModalAuthContainer>
  );
};

export default ModalAuth;

const StyledModalAuthContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9999,
  display: 'flex',
  boxSizing: 'border-box',
  background: 'rgba(31, 29, 26, 0.5)',
  backdropFilter: 'blur(4px)',
});

const StyledModalAuthCard = styled(Card)({
  position: 'absolute !important',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box !important',
  width: '100%',
  maxWidth: '420px',
  padding: '32px',
  backgroundColor: Colors.additional,
  borderRadius: '4px',
  transform: 'translate(-50%, -50%)',
});

const StyledModalAuthStack = styled(Stack)({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& input': {
    backgroundColor: Colors.primary,
  },
});

const StyledButtonFullBtn = styled(Button)({
  height: '56px !important',
  width: '112px',
  marginTop: '24px !important',
  fontWeight: 300,
  fontSize: '18px !important',
});
