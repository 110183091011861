const sortNumber = (array, prop, direction) => {
  const propPath = prop.split('.');

  let sortOrder;
  if (direction === 'asc_') {
    sortOrder = 1;
  } else {
    sortOrder = -1;
  }

  return array.sort((a, b) => {
    let x = a;
    let y = b;
    for (const element of propPath) {
      x = x[element];
      y = y[element];
    }

    // Преобразуем x и y в числа
    const numX = Number(x);
    const numY = Number(y);

    // Извлекаем результат сравнения в отдельную переменную
    let comparisonResult;
    if (numX < numY) {
      comparisonResult = -1;
    } else if (numX > numY) {
      comparisonResult = 1;
    } else {
      comparisonResult = 0;
    }

    return sortOrder * comparisonResult;
  });
};

export default sortNumber;
