import { Box } from '@mui/system';
import { MonthPaymentRow } from './MonthPaymentRow';

export const MonthlyPaymentBody = ({ payments, activeYear }) => {
  return (
    <Box>
      {payments?.[activeYear - 1]?.monthlyPayments?.map(payment => (
        <MonthPaymentRow payment={payment} key={payment?.month} />
      ))}
    </Box>
  );
};
