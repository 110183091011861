import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import styles from './PaymentsSchedule.module.scss';
import { Colors } from '../../../assets/mui-styles/Colors';
import { MonthPaymentRowHeader } from './components/MonthPaymentRow';
import { YearsRow } from './components/YearsRow';
import { MonthlyPaymentBody } from './components/MonthlyPaymentBody';
import { PaymentsScheduleTitle } from './components/PaymentsScheduleTitle';

export const PaymentsSchedule = ({ payments = [] }) => {
  const [activeYear, setActiveYear] = useState(1);

  useEffect(() => {
    setActiveYear(1);
  }, [payments]);

  return (
    <Card sx={{ background: Colors.additional }} className={styles.container}>
      <PaymentsScheduleTitle />
      <YearsRow payments={payments} activeYear={activeYear} onClickYear={setActiveYear} />
      <MonthPaymentRowHeader />
      <MonthlyPaymentBody payments={payments} activeYear={activeYear} />
    </Card>
  );
};
