import styles from './CalculatorFreeButton.module.scss';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

export const CalculatorFreeButton = ({ children, type = 'email', onClick }) => {
  return (
    <>
      <button onClick={onClick} className={styles.btn}>
        {children}
        {type === 'email' && <MailOutlineIcon />}
        {type === 'pdf' && <PictureAsPdfOutlinedIcon />}
      </button>
    </>
  );
};
