import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BlockIds, DOMAIN_RELATIVE } from '../utils/constants';
import { clearUserData } from '../utils/utils';
import ROUTES from './api/routes';
import { fetchProjectsByRole } from './api/api';
import { findFlat } from '../utils/helpers/helpers';

const initialState = {
  projects: [
    {
      id: 0,
      value: '0',
      title: 'Republic',
      url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=427`,
      active: false,
      blockId: BlockIds.REPUBLIC_BLOCK_ID,
    },
    {
      id: 1,
      value: '1',
      title: 'FØRST',
      url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=380`,
      active: true,
      blockId: BlockIds.FORST_BLOCK_ID,
    },
    {
      id: 2,
      value: '2',
      title: 'PORTLAND',
      url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1420`,
      active: false,
      blockId: BlockIds.PORTLAND_BLOCK_ID,
    },
    {
      id: 3,
      value: '3',
      title: 'SOUL',
      url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1246`,
      active: false,
      blockId: BlockIds.SOUL_BLOCK_ID,
    },
    {
      id: 4,
      value: '4',
      title: 'MOMENTS',
      url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1457`,
      active: false,
      blockId: BlockIds.MOMENTS_BLOCK_ID,
    },
  ],
  flats: [],
  bulks: [],
  bulksArr: [],
  houseArr: [],
  activeUrl: null,
  currFlat: null,
  currHouse: null,
  currBulk: null,
  currSection: null,
  defaultBenefits: [],
  isDataFromServerForEqualizerFetched: false,
  benefits: [],
  currBenefit: {},
  newTerm: null,
  newTermPrice: null,
  warningBenefit: false,
  estimateModalStatus: false,
  fetchStatus: 'load',
  fetchBenefitsStatus: 'load',
  mortgagesPrice: [],
  currBenefitPrice: null,
  initialPaymentPrice: [],
  flatPrice: null,
  calcDataPrice: [],
  sectionArr: [],
  currBenefitGroup: '',
  dataForFilter: {
    area: {},
    block: {},
    bulks: [],
    flats: [],
    price: {},
  },
  initialFlats: [],
  activeSwitch: sessionStorage.getItem('activeSwitchEq') || 'lot',
  monthlyPaymentSubsidy: null,
  subsidyTerm: null,
  compareDataCheck: false,
  calcCompareData: null,
};

//получение всех квартир по выбранному проекту
export const fetchFlats = createAsyncThunk('cart/fetchFlats', async url => {
  const response = await fetch(`${url}`, {
    method: 'GET',
  });

  const flats = await response.json();
  return flats;
});

export const fetchFlatsCSV = createAsyncThunk('cart/fetchFlatsCSV', async url => {
  const newURL = url.replace('landing/flat', 'flatcsv');

  const response = await fetch(`${newURL}`, {
    method: 'GET',
  });
  return await response.json();
});

export const fetchDataForFilter = createAsyncThunk('cart/fetchDataForFilter', async url => {
  const response = await fetch(`${url}`, {
    method: 'GET',
  });
  const flats = await response.json();
  return flats;
});

//получение всех бенефитов для калькулятора по прайсу
export const fetchMortgagesPrice = createAsyncThunk('cart/fetchMortgagesPrice', async id => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/v2/flat/mortgage/list?blockId=${id}`, {
    method: 'GET',
  });
  const mortgagesPrice = await response.json();
  return mortgagesPrice;
});

//получение минимального первого взноса для рассчета стоимости по прайс цене
export const fetchInitialPaymentPrice = createAsyncThunk(
  'cart/fetchInitialPaymentPrice',
  async ({ currBenefitPrice, flatPrice }) => {
    const method = window.location.href.includes('managerforma.keep-calm.ru')
      ? '/api/v2/flat/mortgage'
      : '/api/flat/mortgage';
    const response = await fetch(
      `${DOMAIN_RELATIVE}${method}/initialPaiment?mortgageId=${currBenefitPrice}&sum=${flatPrice}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    const result = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return result;
  },
);

// Определние страницы с PDF
export const isLotSelectionPage = () => () => {
  return window.location.href.includes(ROUTES.lotSelection);
};

//получение рассчета по введенным данным
export const fetchBenefits = createAsyncThunk(
  'cart/fetchBenefits',
  async ({ flatId, initialPayment, loanTerm, benefitId }) => {
    const response = await fetch(
      `https://api.forma.ru/v2/flat/mortgage?flatId=${flatId}&initialPayment=${initialPayment}&loanTerm=${loanTerm}&benefitId=${benefitId}`,
      {
        method: 'GET',
      },
    );
    const benefits = await response.json();
    return benefits;
  },
);

//получение рассчета по введенным данным прайс
export const fetchCalcDataPrice = createAsyncThunk(
  'cart/fetchCalcDataPrice',
  async ({ loanTerm, mortgageId, initialPayment, sum }) => {
    const method = window.location.href.includes('managerforma.keep-calm.ru')
      ? '/api/v2/mortgage/flat'
      : '/api/flat/mortgage';
    const response = await fetch(
      `${DOMAIN_RELATIVE}${method}?loanTerm=${loanTerm}&mortgageId=${mortgageId}&initialPayment=${initialPayment}&sum=${sum}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const calcDataPrice = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return calcDataPrice;
  },
);

//выяснить есть ли уже данные для сравнения
export const fetchCompareCalcCheck = createAsyncThunk(
  'cart/fetchCompareCalcCheck',
  async ({ meetingId, loanTerm, initialPayment, benefitId, flatId }) => {
    const response = await fetch(
      `${DOMAIN_RELATIVE}/api/meetings/${meetingId}/calc/benefit/check?loanTerm=${loanTerm}&initialPayment=${initialPayment}&benefit=${benefitId}&flat=${flatId}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const data = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return data;
  },
);

export const fetchCalcCompareData = createAsyncThunk('cart/fetchCalcCompareData', async ({ data, meetingId }) => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/meetings/${meetingId}/calc/benefit`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data }),
  });

  const res = await response.json();
  if (response.status === 401) {
    clearUserData();
  }

  return res;
});

export const fetchDeleteCompareData = createAsyncThunk(
  'cart/fetchDeleteCompareData',
  async ({ benefitId, meetingId }) => {
    const response = await fetch(`${DOMAIN_RELATIVE}/api/meetings/${meetingId}/calc/benefit/${benefitId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return res;
  },
);

export const EqualizerSlice = createSlice({
  name: 'equalizerInfo',
  initialState,
  reducers: {
    //изменение активного проекта
    changeActiveProject: (state, action) => {
      const newState = state.projects.map(item => {
        if (item.value === action.payload) {
          item.active = true;
        } else item.active = false;
        return item;
      });

      state.projects = newState;
      state.benefits = [];
      state.newTerm = null;
    },

    //изменение активного дома
    chooseCurrHouse: (state, action) => {
      state.currHouse = action.payload;
    },

    //изменение активного корпуса
    chooseCurrBulk: (state, action) => {
      state.currBulk = action.payload;
    },

    //изменение активной секции
    chooseCurrSection: (state, action) => {
      state.currSection = action.payload;
    },

    //массив домов
    initHouseArr: (state, action) => {
      state.houseArr = action.payload;
    },

    //массив корпусов
    initBulksArr: (state, action) => {
      state.bulksArr = action.payload;
    },

    changeIsDataFromServerForEqualizerFetched: (state, action) => {
      state.isDataFromServerForEqualizerFetched = action.payload;
    },

    //изменение выбранной квартиры
    chooseCurrFlat: (state, action) => {
      if (action.payload === null) {
        state.currFlat = null;
      } else if (state.flats.length > 0) {
        const { currHouse, currBulk, currSection } = state;

        const flat = findFlat(state.flats, currHouse, currBulk, currSection, action.payload);

        if (!flat) {
          state.currFlat = null;
          return;
        }

        state.currFlat = flat;
        state.defaultBenefits = [];
        if (flat.type !== 'csv') {
          state.defaultBenefits = [
            ...flat.benefits.cash,
            ...flat.benefits.mortgage,
            ...flat.benefits.installment,
          ].filter(item => !item.name.toLowerCase().includes('тест'));
        }
        state.benefits = [];
        state.newTerm = null;
        state.currBenefit = {};
      } else {
        console.log('Нет данных по квартирам');
      }
    },

    //массив секций
    initSectionArr: (state, action) => {
      state.sectionArr = action.payload;
    },

    changeCurrBenefit: (state, action) => {
      if (Object.keys(action.payload).length === 0 && typeof action.payload !== 'number') {
        state.currBenefit = {};
      } else if (state.benefits.length > 0) {
        const newState = state.benefits.filter(item => {
          return item.id === action.payload;
        });
        const defaultState = state.defaultBenefits.filter(item => {
          return item.id === action.payload;
        });
        if (
          (newState[0]?.mortgage !== null && newState[0]?.group === 'mortgage') ||
          newState[0]?.group === 'installment' ||
          newState[0]?.group === 'cash'
        ) {
          if (defaultState[0]?.group === 'installment') {
            state.currBenefit = defaultState[0];
          } else {
            state.currBenefit = newState[0];
          }
        } else if (defaultState[0]?.group === 'installment') {
          state.currBenefit = defaultState[0];
        } else {
          state.warningBenefit = true;
        }
      } else {
        const newState = state.defaultBenefits.filter(item => {
          return item.id === action.payload;
        });
        if (
          (newState[0]?.mortgage !== null && newState[0]?.group === 'mortgage') ||
          newState[0]?.group === 'installment' ||
          newState[0]?.group === 'cash'
        ) {
          state.currBenefit = newState[0];
          state.newTerm = null;
        } else {
          state.warningBenefit = true;
        }
      }
    },

    changeWarningBenefit: (state, action) => {
      state.warningBenefit = action.payload;
    },
    changeCurrBenefitPrice: (state, action) => {
      state.currBenefitPrice = action.payload;
    },
    changeCurrBenefitGroup: (state, action) => {
      state.currBenefitGroup = action.payload;
    },
    changeEstimateModalStatus: (state, action) => {
      state.estimateModalStatus = action.payload;
    },
    changeActiveSwitch: (state, action) => {
      state.activeSwitch = action.payload;
    },
    changeFlatPrice: (state, action) => {
      state.flatPrice = action.payload;
    },

    addNewTerm: (state, action) => {
      if (action.payload !== '') {
        state.newTerm = action.payload;
      } else {
        state.newTerm = '';
      }
    },

    addNewTermPrice: (state, action) => {
      if (action.payload !== '') {
        state.newTermPrice = action.payload;
      } else {
        state.newTermPrice = '';
      }
    },

    updateFlatsList: (state, action) => {
      state.flats = action.payload;
    },
    updateBulks: (state, action) => {
      state.bulks = action.payload;
    },
    updateInitialFlats: (state, action) => {
      state.initialFlats = action.payload;
    },
    changeFetchStatus: (state, action) => {
      state.fetchStatus = action.payload;
    },
    resetState: state => {
      Object.assign(state, initialState);
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchProjectsByRole.fulfilled, (state, action) => {
      state.projects = action.payload.projectsByRole
        .sort((a, b) => a.project_id - b.project_id)
        .map(({ name, project_id }, index) => {
          let active;

          if (sessionStorage.getItem('activeProjectEq')) {
            active = String(index) === sessionStorage.getItem('activeProjectEq');
          } else {
            active = project_id === BlockIds.FORST_BLOCK_ID;
          }

          return {
            id: index,
            value: `${index}`,
            title: name,
            name: name.toLowerCase(),
            url:
              project_id === BlockIds.PORTA_BLOCK_ID || project_id === BlockIds.FRAME_BLOCK_ID
                ? null
                : `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=${project_id}`,
            active,
            blockId: project_id,
          };
        });
      state.fetchStatus = 'load';
    });

    builder.addCase(fetchProjectsByRole.pending, state => {
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchProjectsByRole.rejected, state => {
      state.fetchStatus = 'error';
    });

    builder.addCase(fetchFlats.fulfilled, (state, action) => {
      //фильтрация квартир по свободным + сортировка по номеру (по возрастанию)
      state.dataForFilter = action.payload;
      state.flats = action.payload.flats
        .filter(flat => {
          return flat.status === 'free';
        })
        //фильтрация от рекламных лотов
        .filter(flat => flat.has_advertising_price !== 1)
        //Меняем название 6 корпуса Soul на UNIQUE
        .map(flat => {
          if (flat.block_id === BlockIds.SOUL_BLOCK_ID && flat.bulk.number === 6) {
            return { ...flat, bulk: { ...flat.bulk, name: 'UNIQUE' } };
          }
          return flat;
        })
        .sort((a, b) => {
          if (Number.parseFloat(a.number) > Number.parseFloat(b.number)) {
            return 1;
          }
          if (Number.parseFloat(a.number) < Number.parseFloat(b.number)) {
            return -1;
          }
          return 0;
        });

      //Меняем название 6 корпуса Soul на UNIQUE
      const replaceNumberSixBulkWithUnique = action.payload.bulks.map(bulk => {
        if (bulk.unique && bulk.number === 6) {
          return { ...bulk, name: 'UNIQUE' };
        }
        return bulk;
      });

      state.bulks = replaceNumberSixBulkWithUnique;
      state.fetchStatus = 'load';
    });

    builder.addCase(fetchFlats.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchFlats.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });

    builder.addCase(fetchFlatsCSV.fulfilled, (state, action) => {
      //сортировка по номеру (по возрастанию)
      const sortedFlats = action.payload.flats.slice().sort((a, b) => {
        if (Number.parseFloat(a.number) > Number.parseFloat(b.number)) {
          return 1;
        }
        if (Number.parseFloat(a.number) < Number.parseFloat(b.number)) {
          return -1;
        }
        return 0;
      });
      state.flats = sortedFlats;
      state.initialFlats = sortedFlats;
      state.bulks = action.payload.bulks;
      state.fetchStatus = 'load';
    });

    builder.addCase(fetchFlatsCSV.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchFlatsCSV.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });

    builder.addCase(fetchDataForFilter.fulfilled, (state, action) => {
      state.fetchStatus = 'load';
      state.dataForFilter = action.payload;
    });
    builder.addCase(fetchDataForFilter.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchDataForFilter.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchBenefits.fulfilled, (state, action) => {
      state.benefits = [...action.payload.benefits.mortgage, ...action.payload.benefits.cash].filter(
        item => !item.name.toLowerCase().includes('тест'),
      );

      const benefit = action.payload.benefits.mortgage[0];

      if (benefit.group === 'mortgage' && benefit.mortgage === null) {
        state.warningBenefit = true;
      } else {
        state.currBenefit = benefit;
      }

      state.fetchStatus = 'load';
      state.fetchBenefitsStatus = 'load';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchBenefits.pending, state => {
      state.fetchBenefitsStatus = 'pending';
      state.estimateModalStatus = false;
    });
    builder.addCase(fetchBenefits.rejected, state => {
      console.log('Ошибка при запросе на сервер');

      state.fetchStatus = 'error';
      state.fetchBenefitsStatus = 'error';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchMortgagesPrice.fulfilled, (state, action) => {
      state.mortgagesPrice = action.payload;
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchMortgagesPrice.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchMortgagesPrice.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchInitialPaymentPrice.fulfilled, (state, action) => {
      state.initialPaymentPrice = action.payload;
      if (action.payload.monthlyPaymentSubsidy && action.payload.subsidyTerm) {
        state.monthlyPaymentSubsidy = action.payload.monthlyPaymentSubsidy;
        state.subsidyTerm = action.payload.subsidyTerm;
      } else {
        state.monthlyPaymentSubsidy = null;
        state.subsidyTerm = null;
      }
      state.calcDataPrice = [];
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchInitialPaymentPrice.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchInitialPaymentPrice.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchCalcDataPrice.fulfilled, (state, action) => {
      state.calcDataPrice = action.payload;
      if (action.payload.monthlyPaymentSubsidy && action.payload.subsidyTerm) {
        state.monthlyPaymentSubsidy = action.payload.monthlyPaymentSubsidy;
        state.subsidyTerm = action.payload.subsidyTerm;
      } else {
        state.monthlyPaymentSubsidy = null;
        state.subsidyTerm = null;
      }
      state.fetchStatus = 'load';
      state.fetchBenefitsStatus = 'load';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchCalcDataPrice.pending, state => {
      console.log('Загрузка');
      state.fetchBenefitsStatus = 'pending';
      state.estimateModalStatus = false;
    });
    builder.addCase(fetchCalcDataPrice.rejected, state => {
      console.log('Ошибка при запросе на сервер');

      state.fetchStatus = 'error';
      state.fetchBenefitsStatus = 'error';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchCompareCalcCheck.fulfilled, (state, action) => {
      state.compareDataCheck = action.payload;
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchCompareCalcCheck.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchCompareCalcCheck.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchCalcCompareData.fulfilled, (state, action) => {
      state.calcCompareData = action.payload;
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchCalcCompareData.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchCalcCompareData.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchDeleteCompareData.fulfilled, (state, action) => {
      state.calcCompareData = action.payload;
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchDeleteCompareData.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchDeleteCompareData.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
  },
});

export const {
  changeActiveProject,
  chooseCurrFlat,
  chooseCurrHouse,
  chooseCurrBulk,
  chooseCurrSection,
  changeCurrBenefit,
  addNewTerm,
  addNewTermPrice,
  changeWarningBenefit,
  changeEstimateModalStatus,
  initHouseArr,
  initBulksArr,
  initSectionArr,
  changeCurrBenefitPrice,
  changeFlatPrice,
  changeCurrBenefitGroup,
  updateFlatsList,
  updateBulks,
  updateInitialFlats,
  changeActiveSwitch,
  changeFetchStatus,
  resetState,
  changeIsDataFromServerForEqualizerFetched,
} = EqualizerSlice.actions;

//УРЛ выбранного проекта
export const urlSelector = () => state => {
  const res =
    state.equalizerInfo.projects.filter(item => {
      return item.active === true;
    })[0]?.url || '';
  return res;
};

export const getActiveProject = () => state => {
  const activeProject = sessionStorage.getItem('activeProjectEq') | 1;
  return (
    state.equalizerInfo.projects.find(item => {
      return Number(item.value) === Number(activeProject);
    }) || {}
  );
};

//выбранный проект
export const projectSelector = () => state => {
  const res =
    state.equalizerInfo.projects.filter(item => {
      return item.active === true;
    })[0] || {};
  return res;
};

//все квартиры
export const flatsSelector = () => state => {
  const res = state.equalizerInfo.flats || [];
  return res;
};

//выбранная квартира
export const currFlatSelector = () => state => {
  const res = state.equalizerInfo.currFlat || null;
  return res;
};

//выбранная секция
export const currSectionSelector = () => state => {
  const res = state.equalizerInfo.currSection || null;
  return res;
};

//выбранный дом
export const currHouseSelector = () => state => {
  const res = state.equalizerInfo.currHouse || null;
  return res;
};

//выбранный корпус
export const currBulkSelector = () => state => {
  const res = state.equalizerInfo.currBulk || null;
  return res;
};

//дефолтные условия продажи для выбранной квартиры при загрузке данных
export const defaultBenefitsSelector = () => state => {
  const res = state.equalizerInfo.defaultBenefits || [];
  return res;
};

//условия продажи для выбранной квартиры при пересчете
export const benefitsSelector = () => state => {
  const res = state.equalizerInfo.benefits || [];
  return res;
};

//выбранная программа
export const currBenefitSelector = () => state => {
  const res = state.equalizerInfo.currBenefit || {};
  return res;
};

//срок кредита
export const termSelector = () => state => {
  return state.equalizerInfo.newTerm;
};

//срок кредита по прайс
export const termPriceSelector = () => state => {
  return state.equalizerInfo.newTermPrice;
};

//предупреждение
export const warningBenefitSelector = () => state => {
  return state.equalizerInfo.warningBenefit;
};

//статус модалки загрузки
export const fetchStatusSelector = () => state => {
  return state.equalizerInfo.fetchStatus;
};

//статус белого фона на блоке с подсчетами
export const fetchBenefitsStatusSelector = () => state => {
  return state.equalizerInfo.fetchBenefitsStatus;
};

//все корпуса
export const bulksSelector = () => state => {
  const res = state.equalizerInfo.bulks || [];
  return res;
};

//массив текущих корпусов
export const bulksArrSelector = () => state => {
  const res = state.equalizerInfo.bulksArr || [];
  return res;
};

//массив текущих секций
export const sectionArrSelector = () => state => {
  const res = state.equalizerInfo.sectionArr || [];
  return res;
};

//массив домов
export const houseArrSelector = () => state => {
  const res = state.equalizerInfo.houseArr || [];
  return res;
};

//массив льгот прайс
export const mortgagesPriceSelector = () => state => {
  const res = state.equalizerInfo.mortgagesPrice || [];
  return res;
};

//текущая программа в прас
export const currBenefitPriceSelector = () => state => {
  const res = state.equalizerInfo.currBenefitPrice || null;
  return res;
};

//минимальный первый взнос для квартиры по прайсу
export const initialPaymentPriceSelector = () => state => {
  const res = state.equalizerInfo.initialPaymentPrice || null;
  return res;
};

//прайсовая цена квартиры
export const flatPriceSelector = () => state => {
  const res = state.equalizerInfo.flatPrice || null;
  return res;
};

//дата после рассчетов по прайс квартире
export const calcDataPriceSelector = () => state => {
  const res = state.equalizerInfo.calcDataPrice || [];
  return res;
};

//все проекты
export const projectsSelector = () => state => {
  const res = state.equalizerInfo.projects || [];
  return res;
};

//текущая выбранная группа бенефитов
export const currBenefitGroupSelector = state => {
  const res = state.equalizerInfo.currBenefitGroup || '';
  return res;
};

//выбранный переключатель калькуляторов
export const activeSwitchSelector = () => state => {
  const res = state.equalizerInfo.activeSwitch;
  return res;
};

//текущая выбранная группа бенефитов
export const monthlyPaymentSubsidySelector = state => {
  const res = state.equalizerInfo.monthlyPaymentSubsidy || null;
  return res;
};

//текущая выбранная группа бенефитов
export const subsidyTermSelector = state => {
  const res = state.equalizerInfo.subsidyTerm || null;
  return res;
};

//информация о совпадении расчетов
export const compareDataCheckSelector = state => {
  const res = state.equalizerInfo.compareDataCheck;
  return res;
};

//информация о расчетах для избранных квартир
export const calcCompareDataSelector = state => {
  const res = state.equalizerInfo.calcCompareData || null;
  return res;
};

export const isDataFromServerForEqualizerFetchedSelector = state => {
  return state.equalizerInfo.isDataFromServerForEqualizerFetched;
};

export default EqualizerSlice.reducer;
